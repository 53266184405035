import React from 'react'
import './Styles/Accordian.css'

export default function Accordian() {
  return (
        <div id="section-6" className="mt-5">
      <h2 className="text-center">
        FREQUENTLY ASKED <span className="gt-color fw-bold">QUESTIONS</span>
      </h2>
      <div className="d-flex justify-content-center mt-5">
        <div
          className="accordion accord-custom d-flex gap-3 flex-column"
          id="accordionExample"
        >
          <div className="accordion-item border-0">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accord-font"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is a i-Hybrid 2 wheeler vehicle?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accord-font">
                A i-Hybrid vehicle lets you drive your existing moped or
                bike in both petrol and electric modes with just a button
                switch.
              </div>
            </div>
          </div>
          <div className="accordion-item border-0">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed accord-font"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What do you mean by vehicle conversion?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accord-font">
                Vehicles can be converted into fully electric by removing the
                fuel tank and ICE engine, or a hybrid by adding an electric
                motor and battery without removing the petrol components. Green
                Tiger converts your vehicle into a hybrid.
              </div>
            </div>
          </div>
          <div className="accordion-item border-0">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed accord-font"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Are only two wheeler vehicles converted into hybrids?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body accord-font">
                No, bigger vehicles can be converted as well. But Green Tiger
                Mobility focuses of 2-wheeler mopeds and bikes only.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
