import React from "react";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import "./Blog.css";
import { Link, Outlet } from "react-router-dom";

export default function Blog({ open, setOpen }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full position-relative d-flex gap-4 flex-column">
      {/* nav section */}
      <Nav open={open} setOpen={setOpen} />
      {/* blog section  */}
      <div
        className="container-sm d-flex flex-lg-row flex-column gap-4 justify-content-center blog-container "
        style={{ marginTop: "9rem" }}
      >
        {/* blog content  */}
        <div
          className="d-flex flex-column gap-4 border border-white shadow-lg p-5 blog-content "
          style={{
            width: "75%",
          }}
        >
          <Outlet />
        </div>

        {/* blog lists  */}
        <div
          className="p-3 d-flex gap-5 flex-column blog-lists"
          style={{
            width: "25%",
          }}
        >
          <h4>Latest Update</h4>
          <ul className="d-flex flex-column gap-4">
            <li>
              <Link
                to={"/blogs/conv-process"}
                style={{ textDecoration: "none", color: "black" }}
              >
                GT Conversion Process
              </Link>
            </li>
            <li>
              <Link
                to={"/blogs/dual-powertrain"}
                style={{ textDecoration: "none", color: "black" }}
              >
                What's a i-Hybrid?
              </Link>
            </li>
            <li>
              <Link
                to={"/blogs/choose-dual-powertrain"}
                style={{ textDecoration: "none", color: "black" }}
              >
                Why choose a i-Hybrid for your existing vehicle?
              </Link>
            </li>
            <li>
              <Link
                to={"/blogs/dual-powertrain-benefits"}
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                5 super benefits of converting your petrol vehicle to i-Hybrid
              </Link>
            </li>
            <li>
              <Link
                to={"/blogs/reasons-to-convert-elec+petrol"}
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                4 reasons to convert your vehicle to Electric + Petrol from
                Green Tiger
              </Link>
            </li>
            <li>
              <Link
                to={"/blogs/reason-to-choose-lpf-battery"}
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                These 5 reasons make LFP Battery a better choice for your
                electric vehicle.
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
