import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TextField from "@mui/material/TextField";
import { authentication } from "../../Firebase/index";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import VerifiedIcon from "@mui/icons-material/Verified";
import { showToastMessage } from "../../ToastNotification/ToastFunction";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { book_now_url } from "../../API/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "75vh",
  overflow: "auto",
  "@media(max-width: 998px)": {
    width: "95%",
    height: "65vh",
    p: 1,
    transform: "translate(-50%,-43%)",
  },
};
const marks = [
  {
    value: 5000,
    label: "5,000",
  },

  {
    value: 33000,
    label: "33,000",
  },
];
const btn = [
  { label: "6", id: 1, value: 6 },
  { label: "12", id: 2, value: 12 },
  { label: "18", id: 3, value: 18 },
  { label: "24", id: 4, value: 24 },
  { label: "36", id: 5, value: 36 },
];

export default function EmiCalculator({ open, setOpen }) {
  const [value, setValue] = useState(5000);
  const [current_index, setcurrent_index] = useState(0);
  const [tenure, settenure] = useState(6);
  const [calc_flag, setcalc_flag] = useState(false);

  let loan_amount = 63800 - value;

  const [monthly_emi, setmonthly_emi] = useState(
    parseInt((loan_amount * (1 + 21 / 100)) / (tenure + 0.5))
  );
  useEffect(() => {
    let emi_acc_dwnpymt = parseInt(
      (loan_amount * (1 + 21 / 100)) / (tenure + 0.5)
    );
    setmonthly_emi(emi_acc_dwnpymt);
  }, [value, tenure]);

  const handleClose = () => setOpen(false);

  const handelTenure = (e, index) => {
    settenure(Number(e.target.value));

    setcurrent_index(index);
  };

  return (
    <div>
      <ToastContainer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container">
          <div className="p-2 d-flex justify-content-end    w-100">
            <i
              className="fa-solid fa-square-xmark fs-4 text-success align-self-end"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            ></i>
          </div>
          <div className="p-2 p-sm-4 d-flex flex-column align-items-center gap-5 ">
            <h4>Calculate EMI - Bengaluru</h4>
            <div className="d-flex flex-column gap-4 w-100">
              <div className="w-100">
                <div className="d-flex flex-column align-items-center">
                  <span style={{ cursor: "pointer" }}>Bank</span>
                  <div
                    className=""
                    style={{ width: "20%", border: "1px solid green" }}
                  ></div>
                </div>
                <div
                  className="p-3 d-flex flex-column gap-3 "
                  style={{ width: "", border: "1px solid black" }}
                >
                  <div
                    className="d-flex flex-column gap-3 pb-3 "
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <div className="d-flex justify-content-between">
                      <span>Conversion Amount</span>
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                        <span>55,000</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>GST Rate</span>
                      <div className="d-flex gap-2 align-items-center">
                        <span>16</span>
                        <i className="fa-solid fa-percent"></i>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>GST Amount</span>
                      <div className="d-flex gap-2 align-items-center">
                        <i class="fa-solid fa-indian-rupee-sign"></i>

                        <span>8,800</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Total Amount</span>
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fa-solid fa-indian-rupee-sign"></i>

                        <span>63,800</span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                      <span>Down payment</span>
                      <span>{value}</span>
                    </div>
                    <div className="w-100 d-flex justify-content-center ">
                      <Box sx={{ width: "98%" }}>
                        <Slider
                          aria-label="Custom marks"
                          defaultValue={10000}
                          step={1000}
                          valueLabelDisplay="auto"
                          marks={marks}
                          color="success"
                          sx={{ height: "8px" }}
                          min={5000}
                          max={33000}
                          value={value}
                          onChange={(newValue) =>
                            setValue(newValue.target.value)
                          }
                        />
                      </Box>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Tenure Range (in months)</span>
                      <span>{tenure} Months</span>
                    </div>
                    <div className="d-flex gap-3 justify-content-end">
                      {btn.map((item, i) => (
                        <button
                          style={{
                            width: "60px",
                            border: "none",
                            background: "#00aa13",
                          }}
                          value={item.value}
                          key={item.id}
                          onClick={(e) => handelTenure(e, i)}
                          className={`${
                            current_index === i && "bg-black text-white"
                          }`}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column gap-3 pb-3"
                style={{ borderBottom: "1px solid black" }}
              >
                <div className="align-self-end">
                  <ChildModal setcalc_flag={setcalc_flag} />
                </div>
                {calc_flag && (
                  <>
                    <div className="d-flex justify-content-between">
                      <span>Loan amount</span>
                      <div className="d-flex gap-2 align-items-center">
                        <i class="fa-solid fa-indian-rupee-sign"></i>
                        <span className="fw-bold">{loan_amount}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Interest rate</span>
                      <div className="d-flex gap-2 align-items-center">
                        <span className="fw-bold">21%</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Monthly payable</span>
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                        <span className="fw-bold"> {monthly_emi}/month</span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex flex-column  gap-3">
                <div className="d-flex justify-content-between">
                  <div style={{ width: "70%" }}>
                    <span style={{ fontSize: "12px", color: "gray" }}>
                      Insurance to be purchased separately. Documentation,
                      hypothecation & processing charges, etc.(approx)
                    </span>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <i
                      className="fa-solid fa-indian-rupee-sign"
                      style={{ color: "gray" }}
                    ></i>
                    <span style={{ color: "gray" }}>3222</span>
                  </div>
                </div>
                {/* <div>
                  <Accordion
                    sx={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ padding: "0px" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Features
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px" }}>
                      <li style={{ listStyle: "none" }}>- Processing Fee 1%</li>
                      <li style={{ listStyle: "none" }}>
                        - Foreclosure charges 5%
                      </li>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ padding: "0px" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Document Required{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px" }}>
                      <li style={{ listStyle: "none" }}>- Processing Fee 1%</li>
                      <li style={{ listStyle: "none" }}>
                        - Foreclosure charges 5%
                      </li>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                      padding: "0px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ padding: "0px" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Eligibility
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px" }}>
                      <li style={{ listStyle: "none" }}>- Processing Fee 1%</li>
                      <li style={{ listStyle: "none" }}>
                        - Foreclosure charges 5%
                      </li>
                    </AccordionDetails>
                  </Accordion>
                </div> */}
                <div
                  className="p-2 bg-black d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                >
                  <span className="text-white fs-4 fw-bold">Close</span>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
function ChildModal({ setcalc_flag }) {
  const [open, setOpen] = React.useState(false);
  let [count, setcount] = useState(0);

  const handleOpen = () => {
    if (count === 0) {
      setOpen(true);
      count++;
      setcount(count);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: "25%",
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 998px)": {
      width: "50%",
      p: 1,
    },
    "@media(max-width: 576px)": {
      width: "85%",
    },
  };
  const handleCalc = () => {
    setOpen(false);
    setcalc_flag(true);
  };
  const [expand_form, setexpand_form] = useState(false);
  const [name, setname] = useState("");
  const [verified, setVerified] = useState(false);
  const [loader_show, setloader_show] = useState(false);

  let [mobile_number, setmobile_number] = useState("");
  let [otp, setotp] = useState(new Array(6).fill(""));

  useEffect(() => {
    Verify_otp();
  }, [otp.join("").length === 6]);
  useEffect(() => {
    if (verified) {
      fetch(book_now_url, {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          LeadName: name,
          MobileNumber: mobile_number,
          LeadSourceId: 5,
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          showToastMessage(response.SUCCESS, response.MESSAGE);
        })
        .then(() => {
          setcalc_flag(true);
        });

      // Displaying results to console
    }
  }, [verified === true]);

  const handleOtp = (e, index) => {
    let value = e.target.value.replace(/[^0-9]+/g, "");
    setotp([...otp.map((item, i) => (i === index ? value : item))]);
    if (e.currentTarget.nextElementSibling) {
      e.currentTarget.nextElementSibling.focus();
    }
  };
  const generateRecatpcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "visible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // response.onSignInSubmit();
        },
      },
      authentication
    );
  };

  const RequestOtp = () => {
    if (mobile_number.length === 10 && name.length > 2) {
      setexpand_form(true);
      mobile_number = "+91" + mobile_number;

      generateRecatpcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, mobile_number, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        })
        .then(() => showToastMessage(true, "OTP sent to your number"))
        .catch((error) => {
          // Error; SMS not sent
          showToastMessage(false, error.message);
        });
    } else {
      showToastMessage(false, "Enter valid name and mobile number");
    }
  };
  const Verify_otp = () => {
    if (otp.join("").length === 6) {
      setloader_show(true);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp.join(""))
        .then((result) => {
          // User signed in successfully.
          // ...
          setloader_show(false);
          setVerified(true);
          setOpen(false);
        })

        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          showToastMessage(false, error.message);
        });
    }
  };
  const handelVerify = () => {
    Verify_otp();
  };

  return (
    <React.Fragment>
      {!verified && (
        <button
          onClick={handleOpen}
          style={{ background: "#00aa13", border: "thin solid black" }}
        >
          Calculate
        </button>
      )}

      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="d-flex justify-content-center w-100 ">
            <form action="" className="d-flex flex-column gap-4 w-100">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                sx={{ width: "100%" }}
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
              <div className="d-flex flex-column gap-3">
                <TextField
                  id="outlined-basic"
                  label="Enter Your Mobile Number"
                  variant="outlined"
                  color={verified && "success"}
                  sx={{ width: "100%" }}
                  value={mobile_number}
                  onChange={(e) => setmobile_number(e.target.value)}
                />
                {verified && (
                  <div className="d-flex gap-2 align-self-end align-items-center">
                    <span>Verified</span>
                    <VerifiedIcon sx={{ color: "#00aa13" }} />
                  </div>
                )}
              </div>

              {!verified && (
                <div
                  id="recaptcha-container"
                  className="align-self-end w-100"
                ></div>
              )}
              {loader_show && (
                <CircularProgress
                  color="success"
                  sx={{ alignSelf: "center", marginBottom: "-2rem" }}
                />
              )}
              {expand_form && !verified && (
                <div className="w-100 d-flex justify-content-between ">
                  {otp.map((item, i) => (
                    <input
                      type="text"
                      maxLength={"1"}
                      value={item}
                      onChange={(e) => handleOtp(e, i)}
                      onFocus={(e) => e.target.select()}
                      style={{
                        width: "10%",
                        borderWidth: "0 0 2px",
                        borderColor: "green",
                        outline: "none",
                      }}
                      className="outline_input"
                    />
                  ))}
                </div>
              )}

              <div className="align-self-end">
                {!expand_form && !verified ? (
                  <button
                    type="button"
                    className="border-0  bg-success text-white px-3 p-1"
                    onClick={RequestOtp}
                  >
                    Request OTP
                  </button>
                ) : !verified ? (
                  <button
                    type="button"
                    className="border-0  bg-success text-white px-3 p-1 d-flex justify-content-between"
                    onClick={handelVerify}
                  >
                    <span>Verify OTP</span>
                  </button>
                ) : null}
              </div>
              <div className="d-flex gap-3 align-self-end">
                <button
                  onClick={handleCalc}
                  className="border-0  bg-success text-white px-3 p-1"
                >
                  <KeyboardDoubleArrowRightIcon />
                  <span>skip</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
