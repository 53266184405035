import React, { useState, useEffect } from "react";
import Circularcard from "./Circularcard";
import "./Styles/LeafInhand.css";
import "./grass/style.css";

export default function LeafInhand() {
  let [activeButton, setactiveButton] = useState(null);
  useEffect(() => {
    activeButton = 0;
    let id = setInterval(() => {
      if (activeButton < 5) {
        setactiveButton(activeButton++);
      } else {
        activeButton = 0;
      }
    }, 2000);
  }, []);
  const title = ["GREEN", "RELIABLE", "AFFORDABLE", "SAFE", "SUSTAINABLE"];
  const label = [
    "Save Emission of ~8 trees / yr",
    "2x more reliable than Petrol / EV vehicles with ZERO Range Anxiety",
    "You save more than Rs. 65,000 annually",
    "Safest battery technology in the industry: LFP Battery",
    "Extends the life of your existing vehicle",
  ];
  const baseUrl =
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/";
  const urls = [
    `${baseUrl}Leaf_In_hand_illustrator/green_1.svg`,
    `${baseUrl}Leaf_In_hand_illustrator/green_2.svg`,
    `${baseUrl}Leaf_In_hand_illustrator/green_3.svg`,
    `${baseUrl}Leaf_In_hand_illustrator/green_4.svg`,
    `${baseUrl}Leaf_In_hand_illustrator/green_5.svg`,
  ];
  return (
    <div className="leaf-sec-container">
      {/* GRASS */}
      <div className="grass-container prevent-select">
        <p className="grass-text">
          <span className={`grass-letter ${activeButton == 0 ? "active" : ""}`}>
            G
          </span>
          &nbsp;
          <span className={`grass-letter ${activeButton == 1 ? "active" : ""}`}>
            R
          </span>
          &nbsp;
          <span className={`grass-letter ${activeButton == 2 ? "active" : ""}`}>
            A
          </span>
          &nbsp;
          <span className={`grass-letter ${activeButton == 3 ? "active" : ""}`}>
            S
          </span>
          &nbsp;
          <span className={`grass-letter ${activeButton == 4 ? "active" : ""}`}>
            S
          </span>
        </p>
      </div>
      <div className="circular-div"></div>
      <div className="para-container position-absolute d-flex flex-column">
        <p className=" leaf-title text-center">
          {activeButton === 0 ? (
            <>
              <span className="text-dark-gray fs-2 fw-bold gt-color">
                {title[0]}{" "}
              </span>{" "}
            </>
          ) : activeButton === 1 ? (
            <>
              <span className="text-dark-gray fs-2 fw-bold gt-color">
                {title[1]}{" "}
              </span>{" "}
            </>
          ) : activeButton === 2 ? (
            <>
              <span className="text-dark-gray fs-2 fw-bold gt-color">
                {title[2]}{" "}
              </span>{" "}
            </>
          ) : activeButton === 3 ? (
            <>
              <span className="text-dark-gray fs-2 fw-bold gt-color">
                {title[3]}{" "}
              </span>{" "}
            </>
          ) : activeButton === 4 ? (
            <>
              <span className="text-dark-gray fs-2 fw-bold gt-color">
                {title[4]}{" "}
              </span>{" "}
            </>
          ) : (
            ""
          )}
        </p>
        <p className="circular-para  leaf-description-text">
          {activeButton === 0 ? (
            <span>{label[0]}</span>
          ) : activeButton === 1 ? (
            <span>{label[1]}</span>
          ) : activeButton === 2 ? (
            <span>{label[2]}</span>
          ) : activeButton === 3 ? (
            <span>{label[3]}</span>
          ) : activeButton === 4 ? (
            <span>{label[4]}</span>
          ) : (
            ""
          )}
        </p>
      </div>
      <div
        className={`circular-card circular-card-1 ${
          activeButton === 0 ? "leaf-in-hand-active" : ""
        }`}
      >
        <Circularcard url={`${baseUrl}Leaf_In_hand_illustrator/green_5.svg`} />
      </div>
      <div
        className={`circular-card circular-card-2 ${
          activeButton === 1 ? "leaf-in-hand-active" : ""
        }`}
      >
        <Circularcard url={`${baseUrl}Leaf_In_hand_illustrator/green_1.svg`} />
      </div>
      <div
        className={`circular-card circular-card-3 ${
          activeButton === 2 ? "leaf-in-hand-active" : ""
        }`}
      >
        <Circularcard url={`${baseUrl}Leaf_In_hand_illustrator/green_2.svg`} />
      </div>
      <div
        className={`circular-card circular-card-4 ${
          activeButton === 3 ? "leaf-in-hand-active" : ""
        }`}
      >
        <Circularcard url={`${baseUrl}Leaf_In_hand_illustrator/green_3.svg`} />
      </div>
      <div
        className={`circular-card circular-card-5 ${
          activeButton === 4 ? "leaf-in-hand-active" : ""
        }`}
      >
        <Circularcard url={`${baseUrl}Leaf_In_hand_illustrator/green_4.svg`} />
      </div>
    </div>
  );
}
