import React from "react";
import "./Styles/Testimony.css";
import TestimonialsCard from "../Components/TestimonialsCard";
import Associtaion from "../Components/Association";

export default function Testimony() {
  const baseUrl = "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/";
  const testimonials = [
    {
      imgUrl: `${baseUrl}website_3_0/testimonial_user/67.svg`,
      name: "Abhishek Sharma",
      text: "Mine was one of the beta vehicles that Green Tiger installed and the vehicle is now amazingly quiet! People stop me on the roads & ask me how I got it done!",
    },
    {
      imgUrl: `${baseUrl}website_3_0/testimonial_user/68.svg`,
      name: "Kunal Chhawada",
      text: "I got my fleet of 5 vehicles from  GT office. Since the average kms per day is about 30, the team just charges it once a day. And, everyone is very happy to save the petrol costs!",
    },
    {
      imgUrl: `${baseUrl}website_3_0/testimonial_user/69.svg`,
      name: "Hardik Jadeja",
      text: "I had pre-booked to Green Tiger my vehicle at the Expo & recently got it done. An amazing experience - so quiet, eco-friendly & pocket-friendly!",
    },
  ];
  return (
    <div className="testi-container d-flex flex-column align-items-center justify-content-between">
      <Associtaion />
      <h1 className="text-center people-opinion-asso">
        WHAT PEOPLE&nbsp;
        <span className="fw-bold gt-color ">ARE SAYING</span>
      </h1>
      <div
        id="carouselExampleControls"
        className="carousel slide d-flex justify-content-center testimony-crousel-content"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner carousel-testimony">
          {testimonials.map((t, i) => {
            return (
              <div
                className={`carousel-item ${i === 0 ? "active" : ""}`}
                key={i}
              >
                <TestimonialsCard
                  url={t["imgUrl"]}
                  name={t["name"]}
                  text={t["text"]}
                  key={i}
                />
              </div>
            );
          })}
        </div>
        <div className="carousel-button-group .icon-container">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
        </div>
        <div className="testimony-back-btn">
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="container">
        <div className="carousel-button-group gap-3"></div>
      </div>
    </div>
  );
}
