import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function LocateCard({ item }) {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "250px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {item.Title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <span className="fw-bold">Landmark:</span>
            {item.landmark}
          </Typography>
          <Button
            href={item.link}
            target="_blank"
            variant="contained"
            color="success"
            style={{ marginTop: "10px" }}
          >
            Get Direction
          </Button>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ minWidth: "180px", width: "35%", objectFit: "fill" }}
        image={item.imgUrl}
        alt="Live from space album cover"
      />
    </Card>
  );
}
