import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./milestones.css";
import { useRef } from "react";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: "calc(-50%)",
    right: "calc(50%)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00AA13",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00AA13",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 5,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#00AA13",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#00AA13",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    zIndex: 9,
    border: "8px solid #E1E1E1",
    cursor: "pointer",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default function Milestones() {
  const baseUrl = "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/";
  let steps = [
    {
      imgUrl: `${baseUrl}website_3_0/logo.png`,

      header: "Oct 2020",
      title: "The “Green Tiger” is born",
      desc: "While Ashish had already conceived the idea in 2020, it took a while to build “the team”, which comprised of all the experts from the respective divisions. It started with the IT & Product teams to actually bring the “conceived plan” to reality. ",
    },
    {
      imgUrl: `${baseUrl}website_3_0/milestone_icon/42.svg`,
      header: "March 2021",
      title: "Developed in-house Battery Telematics & Powertrain",
      desc: "In just 6 months, the Green Tiger team built the entire Telematics & the powertrain, in-house! Keeping in mind companies take years to make this happen, turning this around in just 6 months gave us the confidence to take this forward.",
    },
    {
      imgUrl: `${baseUrl}website_3_0/milestone_icon/43.svg`,
      header: "July 2021",
      title: "Built the World’s First i-Hybrid 2-wheeler",
      desc: "This was the month when we had the first working prototype of the i-Hybrid scooter. But, it was critical that we ensure this is safe & road-worthy. So, immediately after this, we started testing it on Indian roads to improve the offering.",
    },
    {
      imgUrl: `${baseUrl}website_3_0/milestone_icon/44.svg`,
      header: "Jan 2022",
      title: "Received a grant of Rs. 2.5cr from the Government of India",
      desc: "This was the first example of “trust” that we garnered once we showed the improved model to ARAI. They loved the concept where we could make the transition to EV very smooth & their grant gave us the impetus to launch this quickly.",
    },
    {
      imgUrl: `https://cdn-icons-png.flaticon.com/512/2163/2163245.png`,
      header: "Aug 2022",
      title: "Won 1st Place in Conquest",
      desc: "With some of the best teams around, we got the ultimate “kudos” (& 10 lakhs!) when we secured the 1st Place in “Conquest” that happened in BITS, Pillani. It was further testimony to our vision to be appreciated by the academic & corporate world.",
    },
    {
      imgUrl: `https://cdn-icons-png.flaticon.com/512/3398/3398733.png`,
      header: "Nov 2022",
      title: "Official Launch of the Scooter",
      desc: "On what is usually known as “Black Friday”, we decided to launch our Petrol + Electric scooter to make this a “Green Friday”. We were invited to IIT Chennai on this day, where we unveiled this to the world.",
    },
  ];
  let steps1 = [
    {
      imgUrl: `${baseUrl}website_3_0/logo.png`,
      header: "OCT 2020",
      title: "This is where it all started",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/42.svg",
      header: "March 2021",
      title: "Developed In-house Battery Telematics & Powertrain",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/43.svg",
      header: "July 2021",
      title: "Built i-Hybrid 2 wheeler",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/44.svg",
      header: "Jan 2022",
      title: "Recieved Rs2.5 Cr Grant",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/45.svg",
      header: "Feb 2022",
      title: "6 Patents Filed",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/46.svg",
      header: "Sept 2022",
      title: "Battery Certification",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
    {
      imgUrl:
        "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/42.svg",
      header: "Nov 2022",
      title: "Vehile Certifiacation in Process",
      desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.`,
    },
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const focusPoint = useRef(null);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const scrollForward = () => {
    focusPoint.current.scrollBy({
      top: 0,
      left: -160,
      behavior: "smooth",
    });
    console.log(focusPoint.current.getBoundingClientRect());
  };
  const scrollBackward = () => {
    focusPoint.current.scrollBy({
      top: 0,
      left: +160,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* Milestones & Awards - Content */}
      <div className="container-fluid text-center d-flex flex-column milestone-container justify-content-evenly">
        <div className="row justify-content-center align-items-center stepper-parent-container">
          <div className="stepper-arrow">
            <div className="col-1">
              <img
                src="https://cdn-icons-png.flaticon.com/128/81/81037.png"
                alt=""
                width={"25px"}
                onClick={scrollBackward}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "18%",
                  left: "5%",
                }}
              />
            </div>
          </div>

          <div
            className="col-9  stepper-container justify-content-center "
            ref={focusPoint}
          >
            {/* back-arrow .... */}

            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
              sx={{
                width: "95%",
                flexShrink: "0",
              }}
            >
              {steps.map((l, i) => (
                <Step key={l.header}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    onClick={handleStep(i)}
                    sx={{ flexShrink: "0", width: "190px" }}
                  >
                    {l.header}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {/* forward arrow  */}
          </div>
          <div className="stepper-arrow">
            <div className="col-1">
              <img
                src="https://cdn-icons-png.flaticon.com/128/81/81068.png"
                alt="error"
                width={"25px"}
                onClick={scrollForward}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "18%",
                  right: "6%",
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="row m-5"></div> */}
        {/* content */}
        <div className="row justify-content-center gap-5 abt-stepper-content ">
          {/* image */}
          <div className="col-11 col-md-3 stepper-img-container">
            <div
              style={{
                backgroundImage: "url('" + steps[activeStep].imgUrl + "')",
              }}
              className="stepper-img"
            ></div>
          </div>
          {/* text */}
          <div className="col-12 col-md-4 stepper-content-text-container">
            <p className="h1">{steps[activeStep].title}</p>
            <p className="h5">{steps[activeStep].desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
