import React from "react";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const blog_lists = [
  {
    id: 1,
    title: "GT Conversion Process",
    imgUrl:
      "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/43.svg",
    summary: `Over the past few months of exhibiting i-Hybrid scooters
    to different people, we have always observed that most people
    are intrigued when they see a vehicle (like their favourite
    Activa) run on both Petrol & Electric on Bangalore roads`,
    path: "/blogs/conv-process",
  },
  {
    id: 2,
    title: "What's a i-Hybrid?",
    imgUrl:
      "https://media.licdn.com/dms/image/D5612AQHBYWzqHzx-tg/article-cover_image-shrink_423_752/0/1667735108731?e=1684972800&v=beta&t=Jn9k7Y_ic2jgx0dwRDv5O4718kIZh9179dPHCBv18ZE",
    summary: `A i-Hybrid vehicle has 2 powertrains in it. It can run on both petrol or electric independently. The rider can switch between the 2 modes at will. When you run out of battery, you can switch to petrol. And when you run out of petrol, you can switch to electric mode.`,
    path: "/blogs/dual-powertrain",
  },
  {
    id: 3,
    title: "Why choose a i-Hybrid for your existing vehicle?",
    imgUrl:
      "https://media.licdn.com/dms/image/D5612AQFVwxUbVP7Z4Q/article-cover_image-shrink_423_752/0/1673154830293?e=1684972800&v=beta&t=w95DB2VtaHvJ8OZgXuEyMTrR6X-SAlUEhkO0mnffAx4",
    summary: `A converted scooter has both electric & petrol modes. you have the flexibility to switch between them using a manual button – and you can ride each of them independently. `,
    path: "/blogs/choose-dual-powertrain",
  },
  {
    id: 4,
    title:
      "5 super benefits of converting your petrol vehicle to i-Hybrid",
    imgUrl:
      "https://media.licdn.com/dms/image/D5612AQGjac0eMyq8KQ/article-cover_image-shrink_423_752/0/1673671875175?e=1684972800&v=beta&t=D1IQKFLOUTzTA1-nAWqQ88vc_deKMmxNdD0hp-GQBMk",
    summary: `Our previous article on LinkedIn covered what a i-Hybrid vehicle is. Today we share the 5 super benefits of converting your petrol scooter to a i-Hybrid vehicle.`,
    path: "/blogs/dual-powertrain-benefits",
  },
  {
    id: 5,
    title:
      "4 reasons to convert your vehicle to Electric + Petrol from Green Tiger",
    imgUrl:
      "https://media.licdn.com/dms/image/D5612AQG2j9UhIAZgvQ/article-cover_image-shrink_423_752/0/1674271917780?e=1684972800&v=beta&t=T-zgnoSzwVnZh8sXbP3lm3Kv_7eZzij-Dp4YMo8r1LM",
    summary: `EV retrofitting, or more popularly, conversion of petrol vehicles to electric is getting more recognition & demand as more & more people are considering getting an electric vehicle.`,
    path: "/blogs/reasons-to-convert-elec+petrol",
  },
  {
    id: 6,
    title:
      "These 5 reasons make LFP Battery a better choice for your electric vehicle.",
    imgUrl:
      "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/43.svg",
    summary: `The battery is a crucial part & considered the heart of an electric vehicle. Whether you buy an electric vehicle or convert your petrol 2-wheeler into one, both options will require a battery to power your ride.`,
    path: "/blogs/reason-to-choose-lpf-battery",
  },
];

export default function BlogLists() {
  return (
    <>
      <h2 className="text-start fs-1">Green Tiger-Blogs</h2>
      <div className="d-flex flex-column gap-4 ">
        {/* blog-description  */}
        {blog_lists.map((item) => {
          return (
            <div
              className="d-flex justify-content-between flex-lg-row flex-column gap-lg-0 gap-4"
              key={item.id}
            >
              <div
                className="align-self-center align-self-lg-auto thumb-nail border border-black"
                style={{ width: "45%", height: "13rem" }}
              >
                <img
                  src={item.imgUrl}
                  alt="error"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div
                className="d-flex flex-column gap-3 align-self-center align-self-lg-auto blog-desc"
                style={{ width: "50%" }}
              >
                <h5 className="fw-bold">{item.title}</h5>
                <div className="d-flex">
                  <p className="text-start text-wrap">
                    {item.summary}
                    <Link
                      className="d-flex align-align-items-center text-success"
                      style={{ cursor: "pointer" }}
                      to={item.path}
                    >
                      Read More
                      <KeyboardDoubleArrowRightIcon color="success" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
