import React from "react";
import "./Styles/Footer.css";
import { Link, useNavigate } from "react-router-dom";
import FooterModal from "./FooterModal/FooterModal";
import { useState } from "react";
export default function Footer() {
  const [open, setOpen] = useState(false);
  let socialIcons = [
    // linkedin
    {
      imgUrl: "https://cdn-icons-png.flaticon.com/512/3536/3536505.png",
      href: "https://www.linkedin.com/company/green-tiger-mobility/?originalSubdomain=in",
    },
    // instagram
    {
      imgUrl: "https://cdn-icons-png.flaticon.com/128/174/174855.png",
      href: "https://www.instagram.com/greentiger_mobility/",
    },
    // fb
    {
      imgUrl: "https://cdn-icons-png.flaticon.com/512/733/733547.png",
      href: "https://www.facebook.com/people/Green-Tiger-Mobility/100082998319252/",
    },
    // youtube
    {
      imgUrl: "https://cdn-icons-png.flaticon.com/128/1384/1384060.png",
      href: "https://www.youtube.com/@greentigermobility84",
    },
    // twitter
    {
      imgUrl: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
      href: "https://twitter.com/mobility_gt/",
    },
  ];
  const navigate = useNavigate();
  return (
    <div>
      <div id="section-7" className=" p-sm-5 p-3">
        <div className="text-white d-flex flex-column">
          <span className="fw-500 fs-4 mb-1"> Legal Info </span>
          <span>Terms</span>
          <Link
            to="/privacy-policy"
            style={{ textDecoration: "none", color: "white" }}
          >
            Privacy Policy
          </Link>
          <span>Refund Policy</span>
          {/* <span onClick={()=>navigate('/FAQ')} style={{cursor:"pointer"}}>FAQ</span> */}
        </div>
        <div className="text-white d-flex flex-column ">
          <span>
            Bommanahalli, <br /> Bengaluru, <br /> Karnataka - 560068
          </span>
          <span>CIN: U50100MH2020PTC348836</span>
          <span>GST:29AAICG6260B1ZO</span>
        </div>
        <div className="text-white d-flex flex-column">
          <span className="fw-500 fs-4 mb-1">Enquiry</span>
          <span>+91 7975592377</span>
          {/* <span
            onClick={() => setOpen(true)}
            className="foot-contact-btn text-center"
          >
            Contact Us
          </span> */}
          <button
            type="button"
            className="btn btn-success data-btn  foot-contact-btn fs-6"
            onClick={() => setOpen(true)}
          >
            Franchise
          </button>
        </div>
        <div className="foot-color d-flex flex-column gap-3">
          <div className="footer-subscribe d-flex">
            <form
              action="
            "
            >
              <div className="input-footer-field">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="py-2"
                />
              </div>
            </form>
            <button>Subscribe</button>
          </div>
          <span>
            <a
              href="https://www.facebook.com/groups/809877613595961/"
              target="_blank"
              className="text-white text-decoration-none fw-bold"
              rel="noreferrer"
            >
              JOIN OUR COMMUNITY
            </a>{" "}
          </span>
        </div>
      </div>
      <FooterModal open={open} setOpen={setOpen} />

      <div
        id="section8"
        className="bg-light w-100 d-flex align-items-center justify-content-between px-5 flex-wrap gap-1 gap-sm-3"
      >
        <div className="green-tiger-logo"></div>
        <span className="text-center sec-8-col">
          All Rights Reserved Green Tiger Mobility Pvt.Ltd &#169;&nbsp;
          {new Date().getYear() + 1900}
        </span>

        <div className="d-flex justify-content-between  gap-5 ">
          <span className="sec-8-col">Follow us</span>
          <div className="d-flex  gap-3 social-icon-container">
            {socialIcons.map((si, i) => {
              return (
                <div
                  className="social-icon"
                  key={i}
                  style={{ backgroundImage: "url('" + si["imgUrl"] + "')" }}
                  onClick={() => {
                    window.open(si["href"], "_blank");
                  }}
                ></div>
              );
            })}
            {/* <img src="" alt="..." width="25px" />
            <img src="" alt="..." width="25px" />
            <img src="" alt="..." width="25px" />
            <img src="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
