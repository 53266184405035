import React from "react";

export default function Blog3() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="d-flex flex-column gap-5">
        <div
          className="m-auto d-flex justify-content-center align-content-center"
          style={{
            width: "85%",
            height: "315px",
          }}
        >
          <img
            src="https://media.licdn.com/dms/image/D5612AQFVwxUbVP7Z4Q/article-cover_image-shrink_423_752/0/1673154830293?e=1684972800&v=beta&t=w95DB2VtaHvJ8OZgXuEyMTrR6X-SAlUEhkO0mnffAx4"
            alt="error"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="d-flex flex-column gap-5">
          <h2 className="text-center">
            Why choose a i-Hybrid for your existing vehicle?
          </h2>
          <div className="d-flex gap-3">
            <img
              src="../../../../assests/favicon/favicon-32x32.png"
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
            <div className="d-flex flex-column gap-1">
              <span>Green Tiger Mobility Pvt. Ltd.</span>
              <span style={{ color: "lightgray" }}>November 6, 2022</span>
            </div>
          </div>
          <div>
            <p>
              Green Tiger has launched the World’s First i-Hybrid
              2-wheeler in the world.
            </p>
            <p>
              A i-Hybrid is defined as Two Power Trains in a
              single vehicle. In the offering by Green Tiger, it’s the Electric
              drive train in addition to the Petrol one.{" "}
            </p>
            <p>
              So, let’s see why you should be converting your existing vehicle
              into a i-Hybrid:{" "}
            </p>
            <ol>
              <li>
                <p className="fw-bold">i-Hybrid Function</p>{" "}
              </li>
              <p className="">
                <i>
                  Ride up to 60 kms on electric with petrol as your back-up.
                </i>{" "}
              </p>
              <p>
                A converted scooter has both electric & petrol modes. you have
                the flexibility to switch between them using a manual button –
                and you can ride each of them independently.
              </p>
              <p>
                The major advantage of a i-Hybrid system is that the 2
                modes are not dependent on each other, so you can continue to
                drive in one mode even if the other mode fails for any reason.
                This makes a i-Hybrid vehicle different from a typical
                Hybrid vehicle.{" "}
              </p>
              <li>
                <p className="fw-bold">Removable Battery </p>
              </li>
              <p>
                <i>Charge the removable battery anywhere. </i>
              </p>
              <p>
                Green Tiger's self-designed portable Battery - powers the
                electric mode and can be easily charged at any 5 AMP socket.{" "}
              </p>
              <p>
                You can carry it to your home to let it charge overnight. Or you
                can charge it at any public place which has a regular charging
                switchboard. It just takes 4 hours to charge from 0-100.{" "}
              </p>
              <li>
                <p className="fw-bold">IP67 Rated Motor </p>
              </li>
              <p>
                <i>Ride carefree in harsh conditions. </i>
              </p>
              <p>
                Our Brushless DC motor is built to withstand rough conditions,
                with an IP67 rating that makes it both water and dust resistant.
                So you can ride confidently in the rain, on muddy roads, and
                even on off-road trails.
              </p>
              <li>
                <p className="fw-bold">IoT & Smart App </p>
              </li>
              <p>
                <i>A connected vehicle that is smart & secure. </i>
              </p>
              <p>
                The converted vehicle comes with an IoT & GPS module inside,
                which links the vehicle to the mobile app and allows you to
                track and manage the vehicle remotely.{" "}
              </p>
              <p>
                The app provides data on things like battery life, petrol cost
                and carbon emissions saved, as well as smart benefits like
                anti-theft measures, accident alerts to emergency contacts, and
                location tracking.
              </p>
              <p>
                All the smart benefits and data points are provided even when
                you ride the vehicle on petrol.
              </p>
              <p>
                So, you can even see how much petrol is left in the tank, right
                from home!{" "}
              </p>
              <p className="fw-bold">Other offerings </p>
              <p>
                In addition to the above benefits, Green Tiger provides 6 months
                Warranty on the electric parts (which can be extended to 3
                years) and the option to pay for your vehicle conversion through
                affordable EMIs.
              </p>
              <p>
                We also handle the process of updating your RC Book (with your
                cooperation).
              </p>
              <p>
                In summary, a GT converted 2-wheeler vehicle includes not only
                the convenience and efficiency of i-Hybrid function but
                also the added benefits of a removable Battery, a rugged &
                reliable Motor, and multiple smart benefits enabled by IoT &
                Mobile App.
              </p>
              <p>
                All of these features combine to give you a highly-customized,
                high-quality converted vehicle that meets all of your mobility
                needs.
              </p>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
