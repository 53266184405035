import React from "react";

export default function Blog1() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column gap-5">
      <div className="w-100">
        <div
          className="m-auto d-flex justify-content-center align-content-center"
          style={{
            width: "75%",
            height: "315px",
          }}
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/milestone_icon/43.svg"
            alt="error"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 mt-3">
        <div>
          <p>
            Over the past few months of exhibiting i-Hybrid scooters to
            different people, we have always observed that most people are
            intrigued when they see a vehicle (like their favourite Activa) run
            on both Petrol & Electric on Bangalore roads
          </p>
          <p>
            This article is for those who are curious to know the retrofitment
            process followed by Green Tiger to make yout vehicle a i-Hybrid one.
          </p>
          <div>
            <p>The process can be divided into 3 broad steps:</p>
            <ul className="" style={{ listStyleType: "decimal" }}>
              <li>Pre-Inspection</li>
              <li>Retrofitment</li>
              <li>Post-Conversion Testing</li>
            </ul>
            <p></p>
          </div>
        </div>
        <div>
          <h3>Pre-Inspection</h3>
          <p>The vehicle inspection is performed to check 2 things.</p>
          <ul>
            <li>
              The primary reason is to see if the vehicle is in a condition to
              be converted, or does something need to be repaired or replaced in
              the vehicle before the actual process.
            </li>
            <li>
              The second is to flag parts of the vehicle that are not
              functioning properly. It quite happens that even if a part is
              damaged; it can still be converted – by just repairing it.
            </li>
          </ul>
          <p>
            Hence, it is critical that such issues are known prior to the
            customer, so he can assess the situation; and is not falsely under
            the impression that a particular problem in the vehicle has occurred
            because of the conversion.
          </p>
          <p>
            The Green Tiger team inspects the overall condition of the vehicle -
            which includes checking the tyres, the suspension, the seat lock
            (needs to be working since the battery is kept in the boot), the
            self-start function and the condition of the 12V Battery which is
            already in the vehicle.
          </p>
          <p>
            The Pre-Inspection takes about 10-15 mins to complete (provided
            there is no further action to be taken).
          </p>
        </div>
        <div>
          <h4>Retrofitment</h4>
          <p>
            After the vehicle has passed all the assessments, it is taken to the
            Bay area. A set of electric components are assigned to the vehicle.
          </p>
          <p>
            The team commences from the rear part of the vehicle. The rear wheel
            of the vehicle is worked upon to fit the Hub Motor & Controller, and
            the Boot is adjusted for the Battery Mount.
          </p>
          <p>
            Meanwhile, the vehicle is opened up on the side and front. The
            existing Throttle cable is replaced with a new one, a Wiring Harness
            is inserted, and a DPDT switch connection is established on the
            front part.
          </p>
          <p>
            Once the Motor & Controller gets fitted, and the Throttle Cable,
            Wiring Harness & DPDT Switch are properly connected, the next step
            is to embed a Display beside the speedometer and integrate the VCU
            (IoT & GPS) box.
          </p>
          <p>
            A quality check is then performed (with a testing Battery) to
            confirm if the vehicle is switching perfectly between Electric &
            Petrol modes.
          </p>
          <p>
            The final steps include covering and routing the electric
            connections and fitting the vehicle body parts back into place. The
            allotted Battery Pack is placed in the Boot.
          </p>
          <p>The entire Retrofitment process is done in just 90 mins.</p>
        </div>
        <div>
          <h4>Post-Conversion Testing</h4>
          <p>
            The final step before delivering it to the customer includes
            performance & data checks.
          </p>
          <p>
            The testing team starts with switching and running the vehicle in
            both Electric & Petrol modes to see if the switchover circuit is
            operating well.
          </p>
          <p>
            Thereafter, all the electric functions of the vehicle such as Brake
            lights & Indicators are checked when the vehicle is in Electric mode
          </p>
          <p>
            The final step is to see if our cloud system is receiving different
            types of data from the vehicle (in both modes). The connected
            vehicle can be tracked and managed through the Mobile app, offering
            smart benefits like Anti-Theft & Fall Alert. All these benefits
            require us to receive accurate and regular data from the vehicle.
          </p>
          <p>The Post-Conversion Testing takes about 10-15 mins to complete.</p>
        </div>
        <div>
          <p>
            To summarise, Green Tiger follows a 3-step process of
            Pre-Inspection, Retrofitment & Post-conversion Testing to convert a
            scooter to i-Hybrid.
          </p>
          <div className="w-100 align-self-center mt-5 d-flex justify-content-center ">
            <div className="blog-video">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/7lr2NTQpuSQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
