import React from "react";
import MultiCarousel from "../../Components/multi-carousel/";
import Nav from "./../../Components/Nav";
import Footer from "./../../Components/Footer";
import Description from "./components/description";
import Milestones from "./components/milestones";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./about-us.css";
import { v4 as uuidv4 } from "uuid";
import Carroussel from "../../Components/3-d Corousel/Carroussel";
import Card from "./components/Card/Card";
import NavigationIcon from "@mui/icons-material/Navigation";
import Fab from "@mui/material/Fab";

export default function AboutUs({ open, setOpen }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  let desc =
    'One of the most debated questions today is: "Electric or Petrol". With six patents, \
    Green Tiger has a very unique and interesting answer - it gives you the "Power to \
    Choose". Green Tiger has developed an electric vehicle solution in-house which can \
    be fitted on existing petrol two wheelers - hence giving you the choice to run your \
    existing scooter as EITHER "Electric" or "Petrol".\n\nThis is similar to a Generator \
    / UPS being used as a backup, when electricity from the grid fails at home. So, the \
    world\'s 1st i-Hybrid gives you the option of an EV vehicle with petrol engine \
    as a backup.\n\nWe have received financial grants from Startup India (DPIIT), ARAI \
    (ultimate industrial body for road worthiness in India), Ministry of Heavy Industries \
    and Ministry of Electronics & IT (MeitY Startup Hub) to bring its i-Hybrid \
    technology to the masses. It is also part of the startup ecosystem at BITS Pilani, \
    IIMB (Sustainability Program with Alstom) and NASSCOM DeepTech Club. GTM is the winner \
    of Conquest 2022 and selected as “FICCI Most Innovative Startup for 2022”.';

  const baseUrl = "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/";
  let details = [
    {
      One: "Conceptualized & started “Transtel” (Clean energy in UK & Italy) & Rooftop solar In India for  (Shapoorji Grp)",
      Two: "MBA from MDI with 18+ yrs exp in Clean Energy & Banking (Transtel, DBS & SCB)",
    },
    {
      One: "Product Head (Streeboo) & founding member at ExtraEdge",
      Two: "PM from IIM & BE (IT) with 8+ yrs exp. at Streeboo, CredR & ExtraEdge",
    },
    {
      One: "MBA from MDI with 18+ yrs experience",
      Two: "Founded and exited 2 businesses with 18 franchises across 4 states",
      Three: "Podcast host: Brain Box (8 seasons)",
      four: "4+ yrs of Sales exp (Wipro, SAP, IBM & GBM, Dubai)",
    },
    {
      One: "Co-founded Pye Tech. (Focusing on  EV powertrain - Motor & Motor Drivers)",
      Two: "BE (Mech) with  7+ yrs exp",
      Three:
        "Worked with Hero MotoCorp (greenfield projects) and other cos (EV powertrain, charging network & Mechatronics)",
    },
    {
      One: "6 years of experience in EV industry",
      Two: "9 years in designing and developing vehicles with a specialization in Automotive Engineering",
      Three: "Headed Product and Engineering at Lithos and UTL EV subsidiary",
    },
  ];

  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/1.png`}
          Title={"Ashish Dokania"}
          desc={"Founder & Chief Executive Officer"}
          details={details}
          linkedUrl={"https://www.linkedin.com/in/ashish-dokania/"}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/2.png`}
          Title={"Aditya Ganjapure"}
          desc={"Chief Operating Officer"}
          details={details}
          linkedUrl={"https://www.linkedin.com/in/aditya-ganjapure-363166110"}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/3.png`}
          Title={"Sourabh Tiwary"}
          desc={"Chief Technology Officer"}
          details={details}
          linkedUrl={"https://www.linkedin.com/in/sourabhtiwary"}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/4.png`}
          Title={"Kaushik Bose"}
          desc={"Chief Business Officer"}
          details={details}
          linkedUrl={"https://www.linkedin.com/in/kaushik-bose"}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/5.png`}
          Title={"Bhavesh Bhojwani"}
          desc={"Head, Power Electronics"}
          linkedUrl={"https://www.linkedin.com/in/bhavesh-bhojwani"}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={`${baseUrl}website_3_0/leadership_members/6.png`}
          Title={"Mahendra Sengar"}
          desc={"Head, Design & Mfg."}
          linkedUrl={"https://www.linkedin.com/in/mahendra-sengar-0a104982"}
        />
      ),
    },
  ];

  let mediaCoverage = [
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/ARAI%20Technovuus.jpg`,
      title: "ARAI Technovus",
      desc: "",
      href: "https://www.linkedin.com/feed/update/urn:li:activity:6904090645825241089",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/Auto_EV_Expo.jpg`,
      title: "Auto EV Expo",
      desc: "",
      href: "https://greentiger1-my.sharepoint.com/personal/kaushik_greentiger_in/_layouts/15/stream.aspx?id=%2Fpersonal%2Fkaushik%5Fgreentiger%5Fin%2FDocuments%2FGT%2FWebsite%203%2E0%2FMedia%20Coverage%2FVideo%2FAuto%20Expo%20KTPO%20Video%2Emp4&ga=1",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/Climafix.jpg`,
      title: "Climafix",
      desc: "",
      href: "https://greentiger1-my.sharepoint.com/personal/kaushik_greentiger_in/_layouts/15/stream.aspx?id=%2Fpersonal%2Fkaushik%5Fgreentiger%5Fin%2FDocuments%2FGT%2FWebsite%203%2E0%2FMedia%20Coverage%2FVideo%2FClimafix%2Emp4&ga=1",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/Conquest.jpg`,
      title: "Conquest",
      desc: "",
      href: "https://greentiger1-my.sharepoint.com/personal/kaushik_greentiger_in/_layouts/15/stream.aspx?id=%2Fpersonal%2Fkaushik%5Fgreentiger%5Fin%2FDocuments%2FGT%2FWebsite%203%2E0%2FMedia%20Coverage%2FVideo%2FConquest%2Emp4&ga=1",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/EVReporter.png`,
      title: "EV Reporter",
      desc: "",
      href: "https://evreporter.com/green-tiger-introduces-dual-powertrain-solutions-for-ice-two-wheelers/",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/RK_The_E_Man.png`,
      title: "R K The E Man",
      desc: "",
      href: "https://youtu.be/wloRURiB0xc",
    },
    {
      imgUrl: `${baseUrl}website_3_0/media_coverage/SSANCHAARI_SHIVA.png`,
      title: "Sanchaari Shiva",
      desc: "",
      href: "https://youtu.be/if7DvOEvt34",
    },
  ];

  return (
    <>
      <Nav open={open} setOpen={setOpen} />
      <div className="about-us-power-to-choose " style={{ marginTop: "110px" }}>
        {/* powert to choose */}
        <Description desc={desc} />
      </div>
      {/* video section */}
      <VideoPlayer />

      <div className="about-us-leadership-team  ">
        {/* Leadership Members - Title */}
        <div className="col-12  title theme-light">
          <h1 className="text-center abt-pow-title">
            GT <span className="gt-color fw-bold">LEADERSHIP</span>
          </h1>
        </div>
        {/* Leadership Members - Content  */}
        {/* <div className="pt-5 pb-5">
          <MultiCarousel
            title={"Our Leadership Members"}
            data={membersData}
            textContainer={true}
          />
        </div> */}
        <div
          className=" d-flex align-items-center carrousel-parent"
          style={{ height: "750px", overflow: "hidden" }}
        >
          <Carroussel
            cards={cards}
            height="80%"
            width="40%"
            margin="auto auto"
            offset={2}
            showArrows={false}
          />
        </div>
        <div className="d-flex justify-content-center w-100 view-more ">
          <Fab
            variant="extended"
            href="https://www.linkedin.com/company/green-tiger-mobility/people/"
            sx={{ width: "250px", background: "white", margin: "auto" }}
            target="_blank"
          >
            <NavigationIcon sx={{ mr: 1 }} />
            View More
          </Fab>
        </div>
      </div>

      {/* Milestones & Awards - Title */}
      <div className="col-12 title theme-light">
        <h1 className="text-center abt-pow-title">
          <span className="gt-color fw-bold">MILESTONES</span> & AWARDS
        </h1>
      </div>
      {/* Milestones & Awards - Content */}
      <Milestones />

      <div className="media-coverage d-flex flex-column justify-content-start justify-content-sm-center">
        {/* Media Coverage - Title */}
        <div className="col-12 title theme-light ">
          <h1 className="text-center abt-pow-title">
            <span className="gt-color fw-bold ">MEDIA</span> COVERAGE
          </h1>
        </div>
        {/* Media Coverage - Content */}
        <div className="pt-5 pb-5 media-coverage-content ">
          <MultiCarousel
            title={"Media Coverage"}
            data={mediaCoverage}
            textContainer={true}
            mediaCov={true}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

function VideoPlayer() {
  return (
    <div className="container text-center pt-5 pb-5">
      <div className="row  justify-content-center">
        <div className="col-9 about-video-container">
          <iframe
            id="ytplayer"
            type="text/html"
            src="https://www.youtube.com/embed/3FPh-F9a7Iw"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}
