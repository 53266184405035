import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function Card({ imagen, Title, desc, details,linkedUrl }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen} alt=""  className={Styles.memImge}/>
      <h2>{Title}</h2>
      <div className={Styles.cardTitle}>
        <span className="fs-5">{desc}</span>
        <a href={linkedUrl} target="_blank"> <img
          src="https://cdn-icons-png.flaticon.com/512/3536/3536505.png"
          alt="error"
          className={Styles.imgSize}
        /></a>
       
      </div>

      {/* <p style={{textAlign:"justify"}}>
        {details &&
          <ul className="d-flex flex-column gap-1">
         {details[0].One && <li className="">{details[0].One}</li>}
          {details[1].Two &&  <li className="">{details[1].Two}</li>}
        </ul>}
        
      </p> */}
      {/* <div className={Styles.btnn}>
        <Button text="Demo" />
        <Button text="Code" />
      </div> */}
    </animated.div>
  );
}

export default Card;
