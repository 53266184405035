import React, { useEffect } from "react";
import "./description.css";

export default function Description({ desc }) {
  const len = 399;

  return (
    <div className="container-fluid text-center ">
      <div className="row justify-content-center gap-5 description-container">
        <div className="col-12 title theme-light">
          <h1 className="text-center abt-pow-title">
            <span className="gt-color fw-bold ">POWER</span> TO CHOOSE
          </h1>
        </div>
        <div className="col-sm-8 col-10 desc desktop-only d-none">
          <p className="h5">{desc}</p>
        </div>
        <div className="col-sm-10 col-10 desc">
          <TruncateText desc={desc} len={len}></TruncateText>
        </div>
      </div>
    </div>
  );
}

function TruncateText({ desc, len }) {
  let [fullContent, changeVal] = React.useState(false);

  const toggleView = () => {
    changeVal(!fullContent);
  };

  return (
    <p className="h5">
      {desc.substring(0, len)}
      {fullContent == true ? desc.substring(len) : ""}
      <span className="toggle-button" onClick={() => toggleView()}>
        &nbsp;[...]
      </span>
    </p>
  );
}
