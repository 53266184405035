import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import "./Calculator.css";
import TextField from "@mui/material/TextField";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Slider from "@mui/material/Slider";
import { authentication } from "../../Firebase/index";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import VerifiedIcon from "@mui/icons-material/Verified";
import { showToastMessage } from "../../ToastNotification/ToastFunction";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { book_now_url } from "../../API/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "75vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  "@media(max-width: 1165px)": {
    width: "95%",
    p: 1,
    height: "65vh",
    transform: "translate(-50%,-43%)",
  },
};

export default function CalculatorModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [scooter_no, setscooter_no] = useState(1);
  const [avg_kms, setavg_kms] = useState(20);
  const [petrol_cost, setpetrol_cost] = useState(60);
  const [year_no, setyear_no] = useState(1);
  const [total_petrol_consumed, settotal_petrol_consumed] = useState(0);
  const [total_petrol_cost, settotal_petrol_cost] = useState(0);
  const [total_elec_charge, settotal_elec_charge] = useState(0);
  const [co2_saved_petrol, setco2_saved_petrol] = useState(0);
  const [co2_gen_elec, setco2_gen_elec] = useState(0);
  const [net_co2_saved, setnet_co2_saved] = useState(0);
  const [net_tree_saved, setnet_tree_saved] = useState(0);
  const [pet_eng_mn_cost, setpet_eng_mn_cost] = useState(0);
  const [elc_eng_mn_cost, setelc_eng_mn_cost] = useState(0);
  const [net_mntc_cost_saved, setnet_mntc_cost_saved] = useState(0);
  const [net_mny_saved, setnet_mny_saved] = useState(0);
  const [calc_flag, setcalc_flag] = useState(false);
  const [sctr_curr_ind, setsctr_curr_ind] = useState(0);
  const [yr_curr_ind, setyr_curr_ind] = useState(0);

  let states = {
    year_no: year_no,
    avg_kms: avg_kms,
    petrol_cost: petrol_cost,
    scooter_no: scooter_no,
  };
  let totalStatesFunction = {
    settotal_petrol_consumed: settotal_petrol_consumed,
    settotal_petrol_cost: settotal_petrol_cost,
    settotal_elec_charge: settotal_elec_charge,
    setpet_eng_mn_cost: setpet_eng_mn_cost,
    setelc_eng_mn_cost: setelc_eng_mn_cost,
    setnet_mntc_cost_saved: setnet_mntc_cost_saved,
    setnet_mny_saved: setnet_mny_saved,
    setco2_saved_petrol: setco2_saved_petrol,
    setco2_gen_elec: setco2_gen_elec,
    setnet_co2_saved: setnet_co2_saved,
    setnet_tree_saved: setnet_tree_saved,
  };
  const handleScooter = (e, i) => {
    setsctr_curr_ind(i);
    setscooter_no(e.target.value);
  };
  const handleYear = (e, i) => {
    setyr_curr_ind(i);
    setyear_no(e.target.value);
  };
  const btns = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    },
  ];
  const marks_km = [
    {
      value: 20,
      label: "20 Km",
    },
    {
      value: 40,
      label: "40 km",
    },
    {
      value: 60,
      label: "60 km",
    },
    {
      value: 80,
      label: "80 km",
    },
    {
      value: 100,
      label: "100 km",
    },
    {
      value: 120,
      label: "120 km",
    },
  ];
  const marks_petrol = [
    {
      value: 60,
      label: "Rs 60",
    },
    {
      value: 80,
      label: "Rs 80",
    },
    {
      value: 100,
      label: "Rs 100",
    },
    {
      value: 120,
      label: "Rs 120",
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column align-items-center">
            <i
              className="fa-solid fa-square-xmark fs-4 text-success align-self-end"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            ></i>
            <h3 className="text-center modal-title">
              CALCULATE SAVINGS BY USING GREEN TIGER VEHICLE
            </h3>
          </div>
          <div className="mt-4   ">
            <form
              className="d-flex flex-column gap-5 pb-5"
              style={{ borderBottom: "1px solid black" }}
            >
              <h4 className="fw-bold">
                Input Data to calculate Savings by using Green Tiger Vehicle
              </h4>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                  <div className="w-auto w-md-75">
                    <span className="fs-5 md-font ">
                      No. of Scooters/Motorcycles Used
                    </span>
                  </div>

                  <span className="fs-5 fw-bold text-dark opacity-75">
                    {scooter_no}
                  </span>
                </div>
                <div className="d-flex gap-3 align-self-end">
                  {btns.map((item, i) => (
                    <button
                      type="button"
                      value={item.value}
                      key={item.id}
                      style={{
                        width: "60px",
                        border: "none",
                        background: "#00aa13",
                      }}
                      className={`${
                        sctr_curr_ind === i && "bg-black text-white"
                      }`}
                      onClick={(e) => handleScooter(e, i)}
                    >
                      {item.value}
                    </button>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                  <div className="w-auto w-md-75">
                    <span className="fs-5">Average Daily Travel (Kms)</span>
                  </div>

                  <span className="fs-5 fw-bold text-dark opacity-75">
                    {avg_kms}
                  </span>
                </div>
                <div
                  style={{ width: "75%" }}
                  className="d-flex align-self-center"
                >
                  <Box sx={{ width: "100%" }}>
                    <Slider
                      aria-label="Custom marks"
                      defaultValue={10}
                      step={10}
                      valueLabelDisplay="auto"
                      marks={marks_km}
                      color="success"
                      min={0}
                      max={120}
                      onChange={(newValue) => setavg_kms(newValue.target.value)}
                    />
                  </Box>
                </div>
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                  <div className="w-auto w-md-75">
                    <span className="fs-5">Petrol Price (Rs.)</span>
                  </div>

                  <span className="fs-5 fw-bold text-dark opacity-75">
                    {petrol_cost}
                  </span>
                </div>
                <div
                  style={{ width: "75%" }}
                  className="d-flex align-self-center"
                >
                  <Box sx={{ width: "100%" }}>
                    <Slider
                      aria-label="Custom marks"
                      defaultValue={60}
                      step={10}
                      valueLabelDisplay="auto"
                      marks={marks_petrol}
                      color="success"
                      min={60}
                      max={120}
                      onChange={(newValue) =>
                        setpetrol_cost(newValue.target.value)
                      }
                    />
                  </Box>
                </div>
              </div>

              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                  <div className="w-auto w-md-75">
                    <span className="fs-5">Years of Usage</span>
                  </div>

                  <span className="fs-5 fw-bold text-dark opacity-75">
                    {year_no}
                  </span>
                </div>
                <div className="d-flex gap-3 align-self-end">
                  {btns.map((item, i) => (
                    <button
                      type="button"
                      value={item.value}
                      key={item.id}
                      style={{
                        width: "60px",
                        border: "none",
                        background: "#00aa13",
                      }}
                      onClick={(e) => handleYear(e, i)}
                      className={`${
                        yr_curr_ind === i && "bg-black text-white"
                      }`}
                    >
                      {item.value}
                    </button>
                  ))}
                </div>
              </div>

              <ChildModal
                states={states}
                totalStatesFunction={totalStatesFunction}
                setcalc_flag={setcalc_flag}
              />
            </form>
            {calc_flag && (
              <>
                <div className="mt-5">
                  <form
                    className="d-flex flex-column gap-4 pb-5"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <h4 className="fw-bold ">
                      What Savings you made personally?
                    </h4>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">Petrol Consumed (Litres)</span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={total_petrol_consumed}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center ">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">Petrol Cost Saved (Rs)</span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={total_petrol_cost}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          Electricity Consumed in Charging (Rs)
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={total_elec_charge}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          Maintenace Cost of I.C. Engine 2-Wheeler
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={pet_eng_mn_cost}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          Maintenace Cost of Electric 2-Wheeler
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={elc_eng_mn_cost}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          Maintenance Cost Saved by Using Electric 2-Wheeler
                          (Rs.)
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={net_mntc_cost_saved}
                      />
                    </div>
                    <div className="p-3 bg-success d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5 text-white">
                          Total Savings by using Electric 2-Wheeler (Rs.)
                        </span>
                      </div>
                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={net_mny_saved}
                      />
                    </div>
                  </form>
                </div>
                <div className="mt-5">
                  <form className="d-flex flex-column gap-4 pb-5">
                    <h4 className="fw-bold">
                      What Savings you made for your Environment?
                    </h4>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          CO2 + Green house gases produced in generating
                          electricity used for charging (Kg)
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={co2_gen_elec}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          CO2 + Other Green house gases reduced by not using
                          Petrol (Kg)
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={co2_saved_petrol}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center">
                      <div className="w-auto w-md-75">
                        <span className="fs-5">
                          Net CO2+Green House Gases reduced (Kg)
                        </span>
                      </div>

                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={net_co2_saved}
                      />
                    </div>

                    <div className="p-3 bg-warning d-flex justify-content-between flex-column gap-3 gap-md-0 flex-md-row align-items-center ">
                      <div className="w-auto w-md-75">
                        <span className="fs-5 text-white">
                          Equivalent to No. of Full Grown Plants
                        </span>
                      </div>
                      <input
                        type="text"
                        style={{
                          minWidth: "250px",
                          width: "325px",
                          padding: "4px",
                          height: "40px",
                        }}
                        value={net_tree_saved}
                      />
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
function ChildModal({ states, totalStatesFunction, setcalc_flag }) {
  const [open, setOpen] = React.useState(false);
  let [count, setCount] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: "25%",
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 998px)": {
      width: "50%",
      p: 1,
    },
    "@media(max-width: 576px)": {
      width: "85%",
    },
  };
  const { year_no, avg_kms, petrol_cost, scooter_no } = states;
  const {
    settotal_petrol_consumed,
    settotal_petrol_cost,
    settotal_elec_charge,
    setpet_eng_mn_cost,
    setelc_eng_mn_cost,
    setnet_mntc_cost_saved,
    setnet_mny_saved,
    setco2_saved_petrol,
    setco2_gen_elec,
    setnet_co2_saved,
    setnet_tree_saved,
  } = totalStatesFunction;
  const handleCalculation = () => {
    if (count === 0) {
      setOpen(true);
      count = count + 1;
      setCount(count);
    }
    let emmision_fator = 2.27193;

    let total_days = 365 * Number(year_no);
    let total_kms = total_days * Number(avg_kms);
    let total_petrol_consumed = total_kms / 35;
    let total_petrol_cost = total_petrol_consumed * Number(petrol_cost);
    let elec_charge = total_kms * 0.25;
    let engn_mntnc_cst = parseInt(4800 * Number(scooter_no) * Number(year_no));
    let elec_mntnc_cst = parseInt(600 * Number(scooter_no) * Number(year_no));
    let net_mntc_cst_saved = engn_mntnc_cst - elec_mntnc_cst;
    let total_mny_saved_elec =
      net_mntc_cst_saved +
      parseInt((total_petrol_cost - elec_charge) * Number(scooter_no));
    let co2_saved = Number(scooter_no) * total_petrol_consumed * emmision_fator;
    let co_saved = total_kms * Number(scooter_no);
    let hc_no_saved = total_kms * Number(scooter_no);
    let gas_prdc_chrging = parseInt(
      Number(scooter_no) * (elec_charge / 7) * 0.85
    );
    let net_gas_reduce = Math.floor(
      co2_saved + co_saved + hc_no_saved - gas_prdc_chrging
    );
    let num_tree = parseInt(net_gas_reduce / 22);

    settotal_petrol_consumed(
      Math.floor(Number(scooter_no) * total_petrol_consumed)
    );
    settotal_petrol_cost(Math.floor(Number(scooter_no) * total_petrol_cost));

    settotal_elec_charge(Math.floor(Number(scooter_no) * elec_charge));
    setpet_eng_mn_cost(engn_mntnc_cst);
    setelc_eng_mn_cost(elec_mntnc_cst);
    setnet_mntc_cost_saved(net_mntc_cst_saved);
    setnet_mny_saved(total_mny_saved_elec);
    setco2_saved_petrol(Math.floor(co2_saved + co_saved + hc_no_saved));
    setco2_gen_elec(gas_prdc_chrging);
    setnet_co2_saved(net_gas_reduce);
    setnet_tree_saved(num_tree);
  };

  const [expand_form, setexpand_form] = useState(false);
  const [name, setname] = useState("");
  const [verified, setVerified] = useState(false);

  let [mobile_number, setmobile_number] = useState("");
  let [otp, setotp] = useState(new Array(6).fill(""));
  const [loader_show, setloader_show] = useState(false);

  useEffect(() => {
    Verify_otp();
  }, [otp.join("").length === 6]);
  useEffect(() => {
    if (verified) {
      fetch(book_now_url, {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          LeadName: name,
          MobileNumber: mobile_number,
          LeadSourceId: 5,
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          showToastMessage(response.SUCCESS, response.MESSAGE);
          console.log("tost");
        })
        .then(() => {
          setcalc_flag(true);
        });

      // Displaying results to console
    }
  }, [verified === true]);

  const handleOtp = (e, index) => {
    let value = e.target.value.replace(/[^0-9]+/g, "");
    setotp([...otp.map((item, i) => (i === index ? value : item))]);
    if (e.currentTarget.nextElementSibling) {
      e.currentTarget.nextElementSibling.focus();
    }
  };
  const generateRecatpcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "visible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // response.onSignInSubmit();
        },
      },
      authentication
    );
  };

  const RequestOtp = () => {
    if (mobile_number.length === 10 && name.length > 2) {
      setexpand_form(true);
      mobile_number = "+91" + mobile_number;

      generateRecatpcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, mobile_number, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
          showToastMessage(true, "OTP sent to your number");
        })
        .catch((error) => {
          // Error; SMS not sent
          showToastMessage(false, error.message);
        });
    } else {
      showToastMessage(false, "Enter valid name and mobile number");
    }
  };
  const Verify_otp = () => {
    if (otp.join("").length === 6) {
      setloader_show(true);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp.join(""))
        .then((result) => {
          // User signed in successfully.
          // ...
          setloader_show(false);
          setVerified(true);
          setOpen(false);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          showToastMessage(false, error.message);
        });
    }
  };
  const handelVerify = () => {
    Verify_otp();
  };

  const handleCalc = () => {
    setOpen(false);
    setcalc_flag(true);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="align-self-md-end align-self-center text-white  "
        style={{
          minWidth: "250px",
          width: "325px",
          padding: "4px",
          background: "#00aa13",
          border: "none",
        }}
        onClick={handleCalculation}
      >
        Calculate
      </button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="d-flex justify-content-center w-100 ">
            <form action="" className="d-flex flex-column gap-4 w-100">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                sx={{ width: "100%" }}
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
              <div className="d-flex flex-column gap-3">
                <TextField
                  id="outlined-basic"
                  label="Enter Your Mobile Number"
                  variant="outlined"
                  color={verified && "success"}
                  sx={{ width: "100%" }}
                  value={mobile_number}
                  onChange={(e) => setmobile_number(e.target.value)}
                />
                {verified && (
                  <div className="d-flex gap-2 align-self-end align-items-center">
                    <span>Verified</span>
                    <VerifiedIcon sx={{ color: "#00aa13" }} />
                  </div>
                )}
              </div>

              {!verified && (
                <div
                  id="recaptcha-container"
                  className="align-self-end w-100"
                ></div>
              )}
              {loader_show && (
                <CircularProgress
                  color="success"
                  sx={{ alignSelf: "center", marginBottom: "-2rem" }}
                />
              )}
              {expand_form && !verified && (
                <div className="w-100 d-flex justify-content-between ">
                  {otp.map((item, i) => (
                    <input
                      type="text"
                      maxLength={"1"}
                      value={item}
                      onChange={(e) => handleOtp(e, i)}
                      onFocus={(e) => e.target.select()}
                      style={{
                        width: "10%",
                        borderWidth: "0 0 2px",
                        borderColor: "green",
                        outline: "none",
                      }}
                      className="outline_input"
                    />
                  ))}
                </div>
              )}

              <div className="align-self-end">
                {!expand_form && !verified ? (
                  <button
                    type="button"
                    className="border-0  bg-success text-white px-3 p-1"
                    onClick={RequestOtp}
                  >
                    Request OTP
                  </button>
                ) : !verified ? (
                  <button
                    type="button"
                    className="border-0  bg-success text-white px-3 p-1 d-flex justify-content-between"
                    onClick={handelVerify}
                  >
                    <span>Verify OTP</span>
                  </button>
                ) : null}
              </div>
              <div className="d-flex gap-3 align-self-end">
                <button
                  onClick={handleCalc}
                  className="border-0  bg-success text-white px-3 p-1"
                >
                  <KeyboardDoubleArrowRightIcon />
                  <span>skip</span>
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
