import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useInput, customFetch } from "../../custom_hook";
import { book_now_url, city_url } from "../../API/api";
import { showToastMessage } from "../../ToastNotification/ToastFunction";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  height: 600,
  overflow: "auto",
  gap: 5,
};

export default function FooterModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [city_drop_menu, setCity_drop_menu] = React.useState([]);
  const [mobile_input, setmobile_input] = React.useState("");
  const name = useInput();
  const email = useInput();
  const comments = useInput();
  const city = useInput();

  React.useEffect(() => {
    customFetch(city_url, { method: "GET" }).then((data) =>
      setCity_drop_menu([...data.DATA])
    );
  }, []);
  const handelSubmit = (e) => {
    e.preventDefault();
    const data_to_upload = {
      LeadName: name.value,
      LeadSourceId: 8,
      MobileNumber: mobile_input,
      Email: email.value,
      Comments: comments.value,
      City: city.value,
    };
    customFetch(book_now_url, { body: data_to_upload, method: "POST" }).then(
      (data) => {
        setOpen(false);
        showToastMessage(
          data.SUCCESS,
          "Thanks for contacting us! We will get back to you"
        );
      }
    );
    name.onChange({ target: { value: "" } });
    setmobile_input("");
    city.onChange({ target: { value: "" } });
    comments.onChange({ target: { value: "" } });
    email.onChange({ target: { value: "" } });
  };
  const handleMobileInput = (event) => {
    const regex = /^[0-9\b]+$/; // Only allow numbers (0-9) and backspace (\b)
    if (
      (event.target.value === "" || regex.test(event.target.value)) &&
      event.target.value.length <= 10
    ) {
      setmobile_input(event.target.value);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="box-modal">
          <div className="d-flex justify-content-between">
            <div className="">
              <h3>Franchise Enquiry</h3>
            </div>
            <div className="close-button">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2961/2961937.png"
                alt="error"
                width={"25px"}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <form className="d-flex flex-column gap-4" onSubmit={handelSubmit}>
            <TextField
              id="outlined-basic"
              label="Enter Your Name"
              variant="outlined"
              {...name}
              required
            />
            <TextField
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              value={mobile_input}
              onChange={handleMobileInput}
              required
            />
            <TextField
              type={"email"}
              id="outlined-basic"
              label="Enter Your Email"
              variant="outlined"
              {...email}
              required
            />
            <div className="d-flex flex-column gap-2">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Location"
                  {...city}
                >
                  {city_drop_menu.map((item) => {
                    return (
                      <MenuItem value={item.Id} key={item.Id}>
                        {" "}
                        {item.City}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <label className="fs-6 text-warning" htmlFor="demo-simple-select">
                We are currently available in Bengaluru only.
              </label>
            </div>
            <TextField
              id="outlined-basic"
              label="Comments"
              variant="outlined"
              {...comments}
            />

            <button type="submit" class="btn btn-success gt-bg">
              Submit
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
