import React from "react";
import "./Styles/Circularcard.css";

export default function Circularcard({ url }) {
  
  return (
    <div
      style={{ backgroundImage: `url(${url})` }}
      className="circular-card-style"
    ></div>
  );
}
