import React from "react";

export default function Blog6() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column gap-5">
      <div
        className="m-auto d-flex justify-content-center align-content-center"
        style={{
          width: "85%",
          height: "315px",
        }}
      >
        <img
          src="https://media.licdn.com/dms/image/D5612AQHBYWzqHzx-tg/article-cover_image-shrink_423_752/0/1667735108731?e=1684972800&v=beta&t=Jn9k7Y_ic2jgx0dwRDv5O4718kIZh9179dPHCBv18ZE"
          alt="error"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="d-flex flex-column gap-5">
        <h2 className="text-center">
          These 5 reasons make LFP Battery a better choice for your electric
          vehicle.
        </h2>
        <div className="d-flex gap-3">
          <img
            src="../../../../assests/favicon/favicon-32x32.png"
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
          <div className="d-flex flex-column gap-1">
            <span>Green Tiger Mobility Pvt. Ltd.</span>
            <span style={{ color: "lightgray" }}>November 6, 2022</span>
          </div>
        </div>
        <div>
          <p>
            The battery is a crucial part & considered the heart of an electric
            vehicle. Whether you buy an electric vehicle or convert your petrol
            2-wheeler into one, both options will require a battery to power
            your ride.
          </p>
          <p>
            And you should know which type of heart is running your electric
            scooter or motorcycle.
          </p>
          <p>
            Most of the EV or retrofitting companies use some type of
            Lithium-Ion battery. The 2 most used Li-ion batteries are NMC and
            LFP batteries. NMC is short for Nickel-Manganese-Cobalt & LFP for
            Lithium-Ferrous-Phosphate.
          </p>
          <p>
            Although NMC chemistry has a higher energy density, LFP batteries
            have multiple advantages. Let us jump into what those advantages are
            and why you might prefer opting for an LFP battery in your vehicle.
          </p>
          <ol>
            <li>
              <p>Longer Life</p>
            </li>
            <li>
              <p>More Stability</p>
            </li>
            <li>
              <p>Save on Maintenance and Replacement Cost</p>
            </li>
            <li>
              <p>Made for Indian Climate</p>
            </li>
            <li>
              <p>Better for Environment</p>
            </li>
          </ol>
          <p className="fw-bold">Longer Life</p>
          <p>
            An LFP battery has more charge/discharge cycles than an NMC battery.{" "}
          </p>
          <p>
            Say you charge a vehicle 100%. And you ride your EV till the battery
            gets empty and needs charging again. That's 1 cycle of
            charge/discharge.
          </p>
          <p>
            It implies that you can fully charge and use a vehicle more times
            with an LFP battery before you eventually replace the battery with a
            new one.
          </p>
          <p className="fw-bold">More Stability</p>
          <p>
            An LFP battery has innately safer cathode material and does not
            decompose at higher temperatures. It is considered both chemically
            and thermally more stable than NMC batteries.
          </p>
          <p>
            So, you are less likely to face any heating or maintenance issues
            with an LFP battery in your vehicle.
          </p>
          <p className="fw-bold">Save on Maintenance and Replacement Cost</p>
          <p>This one is simple to understand. </p>
          <p>
            More stability means you pay less frequently for any maintenance
            issues related to the battery. And longer life means you do not have
            to bear the battery replacement cost for much longer.
          </p>
          <p className="fw-bold">Made for Indian Climate</p>
          <p>
            The recent incidents of EVs catching fire have sparked discussions
            about vehicle safety. While there are several reasons why a vehicle
            might catch fire (called thermal runaway in technical terms), the
            type of battery can contribute to the heating issue.{" "}
          </p>
          <p>
            The typical temperature threshold for an LFP battery to enter a
            thermal runaway is much more (270°C) than in an NMC battery (150°C).
            And if an LFP battery does enter thermal runaway in a rare scenario,
            the heat released from the battery is much less compared to a NMC
            battery.
          </p>
          <p className="fw-bold">Disclaimer:</p>
          <p>
            <i>
              The author is not implying that NMC batteries are unsafe or
              causing the fire incident. Both NMC and LFP batteries have their
              advantages & NMC batteries are an approved & uncontroversial
              choice.
            </i>
          </p>
          <p className="fw-bold">Better for Environment</p>
          <p>
            Any Li-ion battery is better for the environment than petrol or
            Lead-acid based mobility. But LFP batteries are free of toxic
            chemicals such as Nickel and Cobalt present in NMC batteries, thus
            making them the preferred choice again.
          </p>
          <p className="mt-5">
            <span className="fs-6 fw-semibold">To conclude, </span>
          </p>
          <p>
            there are many aspects that you should consider before buying or
            converting to an EV.
          </p>
          <p>
            Which Lithium-Ion battery you use is not a make-or-break choice as
            all of them have their pros and cons. But LFP batteries are
            certainly considered more stable for the Indian roads and their
            adoption is only going to increase.
          </p>
          <p>
            Green Tiger Mobility converts existing petrol 2-wheelers such as
            Honda Activa to i-Hybrid electric vehicles. This enables the
            vehicle to run on both petrol and electricity with just a button
            switch. A 2 in 1 vehicle, if you will. Green Tiger uses an LFP
            battery in its i-Hybrid electric kit.
          </p>
        </div>
      </div>
    </div>
  );
}
