import React from "react";
import "./Styles/Horizontalscroll.css";
export default function Horizontalscroll() {
  return (
    <div className="horz-container d-flex flex-column align-items center py-4 justify-content-evenly ">
      <h1 className="text-center hor-title">
        CONVERT IN <span className="fw-bold gt-color"> 3 STEPS</span>
      </h1>

      <div className="scroll-body  container-fluid d-flex flex-column flex-lg-row gap-lg-0 gap-5 justify-content-lg-evenly align-items-center ">
        <div className="scroll-card">
          <img
            src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/convert_your_vehicle/step1.png"
            alt=""
          />
          <span className="fw-bold vech-convert-font ">STEP 1</span>
          <span className="text-center vech-convert-details  ">
            Book Your Vehicle <br />{" "}
            <span className="fw-bold gt-color">for Conversion</span>
          </span>
        </div>
        <div className="scroll-card">
          <img
            src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/convert_your_vehicle/step2.png"
            alt=""
          />
          <span className="fw-bold vech-convert-font">STEP 2</span>

          <span className="text-center vech-convert-details">
            Your Vehicle gets Converted in <br />
            <span className="fw-bold gt-color">GT Center</span>
          </span>
        </div>
        <div className="scroll-card">
          <img
            src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/convert_your_vehicle/step3.png"
            alt=""
          />
          <span className="fw-bold vech-convert-font">STEP 3</span>

          <span className="text-center vech-convert-details">
            Vehicle is ready. Welcome to <br />
            <span className="fw-bold gt-color">Green Tiger Family</span>{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
