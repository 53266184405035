import React from "react";
import Blog1 from "./blog-pages/Blog1";
import Blog2 from "./blog-pages/Blog2";
import Blog3 from "./blog-pages/Blog3";
import Blog4 from "./blog-pages/Blog4";
import Blog5 from "./blog-pages/Blog5";
import Blog6 from "./blog-pages/Blog6";

import { useLocation } from "react-router-dom";

export default function BlogContent() {
  const location = useLocation();
  let curr_location = location.pathname;
  let paths = {
    path1: "/blogs/conv-process",
    path2: "/blogs/dual-powertrain",
    path3: "/blogs/choose-dual-powertrain",
    path4: "/blogs/dual-powertrain-benefits",
    path5: "/blogs/reasons-to-convert-elec+petrol",
    path6: "/blogs/reason-to-choose-lpf-battery",
  };

  return (
    <>
      {curr_location === paths.path1 ? (
        <Blog1 />
      ) : curr_location === paths.path2 ? (
        <Blog2 />
      ) : curr_location === paths.path3 ? (
        <Blog3 />
      ) : curr_location === paths.path4 ? (
        <Blog4 />
      ) : curr_location === paths.path5 ? (
        <Blog5 />
      ) : curr_location === paths.path6 ? (
        <Blog6 />
      ) : null}
    </>
  );
}
