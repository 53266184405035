import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Pending() {
    const navigate=useNavigate()
  return (
    <>
    <div style={style.container}>
        
        
    </div>
    <div style={style.message}>

        <h2 style={style.h}>This Page is Under Construction</h2>
    </div>
        <button style={style.button} onClick={()=>navigate('/')}>Return</button>
    </>

  )
}
const style={
    container:{
        width:"100%",
        height:"100vh",
        backgroundImage:`url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/under-construction.jpg")`,
        filter:"blur(5px)",
        backgroundRepeat:"no-repeat",
        backgroundPosition:"center",
        backgroundSize:"cover",
        position:"relative",
        


    },
    button:{
    position:"absolute",
    left: "3vw",
    top: "3vw",
    padding: "5px",
    borderRadius: "5px",
    background: "#ffffffb5",
    cursor: "pointer",
    border:"none"

    },
    message:{
        width:"100%",
        height:"100vh",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position:'absolute',
        top:"0",
        color:"white",

    },
    h:{
wordSpacing:"1rem",
letterSpacing:"8px",
fontSize:"44px"
    }
}