import { Button } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";

export default function MultiCarousel({
  title,
  data,
  textContainer,
  mediaCov,
}) {
  let carousel = Carousel;

  const jumpToNext = () => {
    carousel.next();
  };
  const jumpToPrev = () => {
    carousel.previous();
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 998 },
      items: mediaCov ? 3 : 4,
    },
    tablet: {
      breakpoint: { max: 998, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const openLink = (d) => {
    if (typeof d["href"] != "undefined") {
      window.open(d["href"], "_blank");
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <div className="carousel-button-group mt-3 d-none">
        <Button
          color="success"
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => previous()}
        >
          <div className="icon-back"></div>
        </Button>
        <Button onClick={() => next()} color="success">
          <div className="icon-forward"></div>
        </Button>
      </div>
    );
  };
  return (
    <div className="container-fluid text-center">
      <div className="row justify-content-center align-items-center">
        <div className="col-2 carousel-button-group">
          <div className="icon-container" onClick={() => jumpToPrev()}>
            <div className="icon-back"></div>
          </div>
        </div>
        <div className="col-8 p-2 ">
          <Carousel
            ref={(el) => (carousel = el)}
            responsive={responsive}
            slidesToSlide={1}
            swipeable
            autoPlaySpeed={3000}
            infinite
            autoPlay
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
          >
            {data.map((d, i) => (
              <div
                className={`item-container d-flex ${
                  mediaCov && "media-cov-contain"
                }`}
                key={i}
                onClick={() => openLink(d)}
              >
                <div
                  className="multi-carousel-img"
                  style={{ backgroundImage: "url('" + d.imgUrl + "')" }}
                ></div>

                {textContainer ? (
                  <div className="text-container">
                    <div className="carousel-text fw-bold">{d.title}</div>
                    <div className="carousel-desc">{d.desc}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </Carousel>
        </div>
        <div className="col-2 carousel-button-group">
          <div className="icon-container" onClick={() => jumpToNext()}>
            <div className="icon-forward"></div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3"></div>
        <div className="col-3"></div>
      </div>
    </div>
  );
}
