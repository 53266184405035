import React from "react";

export default function Blog4() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column gap-5">
      <div
        className="m-auto d-flex justify-content-center align-content-center"
        style={{
          width: "85%",
          height: "315px",
        }}
      >
        <img
          src="https://media.licdn.com/dms/image/D5612AQGjac0eMyq8KQ/article-cover_image-shrink_423_752/0/1673671875175?e=1684972800&v=beta&t=D1IQKFLOUTzTA1-nAWqQ88vc_deKMmxNdD0hp-GQBMk"
          alt="error"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="d-flex flex-column gap-5">
        <h2 className="text-center">
          5 super benefits of converting your petrol vehicle to i-Hybrid
        </h2>
        <div className="d-flex gap-3">
          <img
            src="../../../../assests/favicon/favicon-32x32.png"
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
          <div className="d-flex flex-column gap-1">
            <span>Green Tiger Mobility Pvt. Ltd.</span>
            <span style={{ color: "lightgray" }}>November 6, 2022</span>
          </div>
        </div>
        <div>
          <p>
            Our previous article on LinkedIn covered what a i-Hybrid
            vehicle is. Today we share the 5 super benefits of converting your
            petrol scooter to a i-Hybrid vehicle.
          </p>
          <p>
            What may seem like a simple act of adding another power source to
            your petrol vehicle can have a large impact on your mobility
            experience.
          </p>
          <p>
            The 5 benefits of converting your vehicle to i-Hybrid are :
          </p>
          <ul>
            <li>
              <p>No range anxiety</p>
            </li>
            <li>
              <p>Ride safely anywhere and anytime</p>
            </li>
            <li>
              <p>Extend vehicle's life</p>
            </li>
            <li>
              <p>Own EV at affordable price</p>
            </li>
            <li>
              <p>Save up to ₹65,000 every year</p>
            </li>
          </ul>
          <p className="fw-bold">No Range Anxiety</p>
          <p>
            The world is gradually shifting towards EV mobility because of
            rising petrol prices & a better riding experience provided by
            electric vehicles.{" "}
          </p>
          <p>
            But EVs have multiple challenges like limited charging stations and
            potential vehicle issues (as the technology is new) that leads to
            range anxiety in riders.
          </p>
          <p>
            A i-Hybrid vehicle has electric & petrol modes, which the
            rider can switch manually. You can ride up to 60kms on electric and
            use petrol as backup if you have gone for a longer ride or your
            battery has drained.
          </p>
          <p className="fw-bold">Ride Safely Anywhere and Anytime</p>
          <p>
            Women who use scooters for their daily commute or ride at night will
            feel safer with a i-Hybrid vehicle as their mobility partner.
          </p>
          <p>
            You can easily switch to another mode and continue your journey if
            the vehicle stops running in 1 mode for any reason.
          </p>
          <p>
            Green Tiger also integrates IoT device in the vehicle and gives you
            a mobile app to track and manage the vehicle remotely. This makes
            your vehicle smart and secure. We will write about the smart
            benefits in more depth in one of our future articles.
          </p>
          <p className="fw-bold">Extend Vehicle's Life</p>
          <p>
            Many of you have an emotional connection with your scooter. Maybe
            it's your first-ever scooter. Or it's a vehicle that you have bought
            with your hard-earned money.
          </p>
          <p>
            You give 2nd life to your vehicle when you convert it to i-Hybrid.
            You also contribute to a sustainable future by reusing
            your vehicle further and reducing waste from metal scrappage.
          </p>
          <p className="fw-bold">Own EV at Affordable Price</p>
          <p>
            Electric vehicles are still very new in the market. It will take
            many years before they can be affordable and the technology can be
            more reliable.
          </p>
          <p>
            A i-Hybrid vehicle lets you experience an EV while retaining
            the comfort of your existing vehicle, and that too at an affordable
            price.
          </p>
          <p className="fw-bold">Save up to ₹65,000 Every Year</p>
          <p>
            Every time you ride the vehicle in electric mode, you save money on
            petrol. And the more you ride, the more money you will save.
          </p>
          <p>
            If you ride around 35 kms every day, you can save up to ₹65,000
            annually. So a i-Hybrid vehicle not only lets you own an EV
            at a reasonable price it saves you a lot of money as well.
          </p>
          <p>
            In summary, converting your petrol 2-wheeler to electric + petrol
            will improve your mobility and other aspects of life. It's reliable,
            safe, sustainable, affordable and helps you earn more through
            savings.
          </p>
        </div>
      </div>
    </div>
  );
}
