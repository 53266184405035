import React from "react";

export default function Blog2() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="d-flex flex-column gap-5">
      <div
        className="m-auto d-flex justify-content-center align-content-center"
        style={{
          width: "85%",
          height: "315px",
        }}
      >
        <img
          src="https://media.licdn.com/dms/image/D5612AQHBYWzqHzx-tg/article-cover_image-shrink_423_752/0/1667735108731?e=1684972800&v=beta&t=Jn9k7Y_ic2jgx0dwRDv5O4718kIZh9179dPHCBv18ZE"
          alt="error"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="d-flex flex-column gap-5">
        <h2 className="text-center">
          What's a i-Hybrid Vehicle? A Quick Read.
        </h2>
        <div className="d-flex gap-3">
          <img
            src="../../../../assests/favicon/favicon-32x32.png"
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
          <div className="d-flex flex-column gap-1">
            <span>Green Tiger Mobility Pvt. Ltd.</span>
            <span style={{ color: "lightgray" }}>November 6, 2022</span>
          </div>
        </div>
        <div>
          <p>A scooter or motorcycle runs on a single powertrain.</p>
          <p>
            A powertrain is a system (made of different components) inside the
            vehicle that allows it to move using a particular power source.
          </p>
          <p>
            So, a vehicle can typically have either an ICE powertrain & run on
            petrol, or it can have an electric powertrain and run as an EV on
            battery.
          </p>
          <p>
            A i-Hybrid vehicle has 2 powertrains in it. It can run on
            both petrol or electric independently. The rider can switch between
            the 2 modes at will. When you run out of battery, you can switch to
            petrol. And when you run out of petrol, you can switch to electric
            mode.{" "}
          </p>
          <p>
            The petrol & electric modes function separately and do not affect
            each other if any one system malfunctions.
          </p>
          <p>
            A i-Hybrid vehicle virtually offers endless range & always
            has a 2nd engine as backup if your vehicle breaks down.
          </p>
          <p>
            Green Tiger has developed this unique & impactful technology for
            2-wheeler riders. GT converts an existing petrol vehicle to petrol +
            electric.
          </p>
        </div>
      </div>
    </div>
  );
}
