import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDx9_k7Ukn9mi6HYSNj77T3M1AV8c-_s64",
  authDomain: "gt-web-af30b.firebaseapp.com",
  projectId: "gt-web-af30b",
  storageBucket: "gt-web-af30b.appspot.com",
  messagingSenderId: "462255157144",
  appId: "1:462255157144:web:5d29afa674ab9c6c07c5ed",
  measurementId: "G-REE8YXR3YW",
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
