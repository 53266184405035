import React from "react";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import LocateCard from "./Components/locate_card";
import "./Components/Locate.css";
const centers_list = [
  {
    id: "1",
    Title: "Bommanahalli Office",
    landmark: "Near ICICI Bank",
    link: "https://goo.gl/maps/Ycr31nLbPXY2iepu6",
    imgUrl: "https://media.glassdoor.com/lst2x/c7/c8/5d/22/office.jpg",
  },
  {
    id: "2",
    Title: "JP Nagar Experience Centre",
    landmark: "Near Caffe Pascucci",
    link: "https://goo.gl/maps/23odpS8vLovYUBZc9",
    imgUrl:
      "https://lh3.googleusercontent.com/p/AF1QipNtiRNq9_OWwvJJ2TpUmsDp2HKMNx04x6Pg7DFu=s680-w680-h510",
  },
  // {
  //   id: "3",
  //   Title: "Pune Battery Plant Address",
  //   landmark: "Near  Dutt Mandir",
  //   link: "https://goo.gl/maps/deWGYT7GR3Ztzahu6",
  //   imgUrl:
  //     "https://res.cloudinary.com/tataautocomp/image/upload/c_fill,g_auto,w_448,h_359,dpr_2/f_auto,q_auto/v1647876885/web/Module-Assembly.jpg?_i=AA",
  // },
];

export default function Locate() {
  return (
    <div className="">
      <Nav />
      <div style={{ marginTop: "7rem" }}>
        <div className="container-sm">
          <div className="d-flex gap-5 justify-content-center flex-lg-nowrap align-items-center flex-wrap locate-card-container">
            {centers_list.map((item) => {
              return (
                <div className="" style={{ minWidth: "360px", width: "60%" }}>
                  <LocateCard item={item} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-5 w-100 ">
          <Footer />
        </div>
      </div>
    </div>
  );
}
