import React from "react";
import "../Styles/VideowithTxtanim.css";
import { TypeAnimation } from "react-type-animation";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./app-feature.css";
export default function AppFeatures({
  children,
  videolink,
  title,
  animationSeq,
  url,
  bg,
  gtalign,
  typingwithLogo,
}) {
  let c = "align-items-center justify-content-evenly";
  let [imgNumber, setimageNumber] = useState(0);
  let [bgNumber, setbgNumber] = useState(0);
  useEffect(() => {
    let id1 = setInterval(() => {
      if (imgNumber < 5) {
        imgNumber += 1;
        setimageNumber(imgNumber);
      } else {
        imgNumber = 0;
        setimageNumber(imgNumber);
      }
    }, 3430);
    let id2 = setInterval(() => {
      if (bgNumber < 4) {
        bgNumber += 1;
        setbgNumber(bgNumber);
      } else {
        bgNumber = 0;
        setbgNumber(bgNumber);
      }
    }, 3430);
    return () => {
      clearInterval(id1);
      clearInterval(id2);
    };
  }, []);
  const bg_app = [
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Home.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Issue.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Locate.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Menu.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Notifications.svg",
  ];

  return (
    <div className=" gt-new-app-container d-flex position-relative ">
      {bgNumber === 0 && (
        <div
          className={"gt-app-left-part bg-active"}
          style={{
            backgroundImage: `url(${bg_app[0]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
      {bgNumber === 1 && (
        <div
          className={"gt-app-left-part bg-active"}
          style={{
            backgroundImage: `url(${bg_app[1]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
      {bgNumber === 2 && (
        <div
          className={"gt-app-left-part bg-active"}
          style={{
            backgroundImage: `url(${bg_app[2]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}

      {bgNumber === 3 && (
        <div
          className={"gt-app-left-part bg-active"}
          style={{
            backgroundImage: `url(${bg_app[3]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
      {bgNumber === 4 && (
        <div
          className={"gt-app-left-part bg-active"}
          style={{
            backgroundImage: `url(${bg_app[4]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
      <div
        className={`gt-app-right-part d-flex flex-column justify-content-center  ${
          typingwithLogo && ""
        } `}
      >
        <h1 className="gt-para-first-word fw-bold gt-color text-center  gt-head-app ">
          {title}
        </h1>

        <div
          className="type-animation-container  type-gt-anima"
          style={{ height: "100px" }}
        >
          <TypeAnimation
            sequence={animationSeq}
            wrapper="p"
            cursor={false}
            repeat={Infinity}
            style={{
              fontSize: "42px",
              color: "black",
              textAlign: "center",
            }}
          />
        </div>

        <div
          className="d-flex   justify-content-center align-align-items-center gt-logo-app-gt  "
          style={{ height: "100px" }}
        >
          {typingwithLogo && (
            <>
              {imgNumber === 0 && (
                <img
                  src={url[0]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "250px",
                    height: "450px",
                    objectFit: "contain",
                  }}
                />
              )}
              {imgNumber === 1 && (
                <img
                  src={url[1]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "350px",
                    height: "450px",
                    objectFit: "contain",
                  }}
                />
              )}
              {imgNumber === 2 && (
                <img
                  src={url[2]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "350px",
                    height: "450px",
                    objectFit: "contain",
                  }}
                />
              )}
              {imgNumber === 3 && (
                <img
                  src={url[3]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "350px",
                    height: "450px",
                    objectFit: "contain",
                  }}
                />
              )}
              {imgNumber === 4 && (
                <img
                  src={url[4]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "350px",
                    height: "450px",
                    objectFit: "contain",
                  }}
                />
              )}
              {imgNumber === 5 && (
                <img
                  src={url[5]}
                  className="imag-animation"
                  alt="error"
                  style={{
                    width: "350px",
                    height: "350px",
                    objectFit: "contain",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
