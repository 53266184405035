import React from "react";
import MultiCarousel from "./multi-carousel";
export default function Association() {
  const baseUrl = "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/";
  let carouselData = [
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/11.png`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/12.png`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/13.png`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/14.png`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/81.svg`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/16.webp`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/19.svg`,
      title: "",
      desc: "",
    },
    {
      imgUrl: `${baseUrl}website_3_0/associations_logos/80.svg`,
      title: "",
      desc: "",
    },
  ];
  return (
    <div
      id="associations"
      className="container-fluid d-flex flex-column justify-content-evenly"
      style={{ height: "63%" }}
    >
      <h1 className="text-center asso-header">
        OUR <span className="fw-bold gt-color">ASSOCIATIONS</span>
      </h1>
      <div className="d-flex justify-content-evenly align-items-center association-carousel-container">
        <MultiCarousel data={carouselData} textContainer={false} />
      </div>
    </div>
  );
}
