import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const showToastMessage = (type, message) => {
  type
    ? toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    : toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
};
