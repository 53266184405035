import React from "react";
import { Link } from "react-router-dom";

export default function Blog5() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="d-flex flex-column gap-5">
        <div
          className="m-auto d-flex justify-content-center align-content-center"
          style={{
            width: "85%",
            height: "315px",
          }}
        >
          <img
            src="https://media.licdn.com/dms/image/D5612AQG2j9UhIAZgvQ/article-cover_image-shrink_423_752/0/1674271917780?e=1684972800&v=beta&t=T-zgnoSzwVnZh8sXbP3lm3Kv_7eZzij-Dp4YMo8r1LM"
            alt="error"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="d-flex flex-column gap-5">
          <h2 className="text-center">
            4 reasons to convert your vehicle to Electric + Petrol from Green
            Tiger
          </h2>
          <div className="d-flex gap-3">
            <img
              src="../../../../assests/favicon/favicon-32x32.png"
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
            <div className="d-flex flex-column gap-1">
              <span>Green Tiger Mobility Pvt. Ltd.</span>
              <span style={{ color: "lightgray" }}>November 6, 2022</span>
            </div>
          </div>
          <div>
            <p>
              EV retrofitting, or more popularly, conversion of petrol vehicles
              to electric is getting more recognition & demand as more & more
              people are considering getting an electric vehicle.
            </p>
            <p>
              While EV conversion is possible in different categories of
              vehicles, we will keep this article relevant to 2-wheeled
              vehicles. If you own a petrol scooter and are interested in
              converting your scooter (or motorcycle) to electric, there are
              different companies available in the market.
            </p>
            <p>
              But if you are going to invest your hard-earned money for
              something that will affect your daily commute majorly, its better
              to look at both the solution being offered and who is offering it.
            </p>
            <p>
              In today's article, we will cover why you should choose Green
              Tiger to convert your 2-wheeler vehicle.
            </p>
            <ol>
              <li>
                <p> GT offers i-Hybrid conversion</p>
              </li>
              <li>
                <p> Approval, Backings & Achievements</p>
              </li>
              <li>
                <p>More Benefits (USPs)</p>
              </li>
              <li>
                <p> In-House Design</p>
              </li>
            </ol>
            <p>
              While other companies will convert your vehicle to either pure
              electric or hybrid (dependent systems), Green Tiger converts your
              petrol vehicle to electric + petrol (i-Hybrid). You can
              ride your vehicle as an EV with petrol as back-up. If the vehicle
              stops moving in 1 mode for any reason, you can continue riding the
              vehicle in the other mode indefinitely.
            </p>
            <p>
              While vehicle conversion is a sensible option to save on fuel
              cost, contribute to environment and shift to an electric vehicle,
              it makes no sense to spend a ton on pure electric conversion. You
              can buy a new Electric Vehicle instead.
            </p>
            <p>
              <Link to="/blogs/dual-powertrain-benefits">
                i-Hybrid conversion solves for range anxiety and vehicle
                break-down issues and is a more pocket-friendly EV option.
              </Link>
            </p>
            <p className="fw-bold">Approval, Backings & Achievements</p>
            <p>
              Green Tiger is the World's 1st company to get approval from
              Automotive Research Authority of India (ARAI) for DPT conversion
              and one of the very few companies to get approval for any type of
              electric retrofitting on 2-wheelers.
            </p>
            <p>Green Tiger has:</p>
            <ul>
              <li>
                <p>
                  Signed an MOU and received Grant from Ministry of Heavy
                  Industries (MHI) for development of i-Hybrid
                  technology.
                </p>
              </li>
              <li>
                <p>
                  Received financial Grant and support from TechNovuus by ARAI.
                </p>
              </li>
              <li>
                <p>
                  Received financial Grant from Ministry of Electronics and
                  Information Technology (MEITY)
                </p>
              </li>
              <li>
                <p>Registered under Start-Up India</p>
              </li>
              <li>
                <p>Incubated in NASSCOM Deep Tech Club</p>
              </li>
            </ul>
            <p>
              Additionally Green Tiger has won multiple awards & recognitions
              for its product, such as the 'Best PAN India Startup' awarded by
              Conquest BITS and 'Most Innovative Startup' by FICCI & Indian
              Express.
            </p>
            <p className="fw-bold">More Benefits (USPs)</p>
            <p>
              <span>
                Other than the i-Hybrid function, which is offered only
                by Green Tiger,
              </span>
              <Link to={"/blogs/choose-dual-powertrain"}>
                GT offers unique benefits such as Removable Battery, IP67 Rated
                Motor, and IoT & mobile app connection to manage & track the
                vehicle remotely.
              </Link>
            </p>
            <p className="fw-bold">In-House Design</p>
            <p>
              Green Tiger's biggest strength is its highly experienced product
              team. The entire electric powertrain has been designed in-house by
              our engineers. This prevents dependency on 3rd parties and is more
              reliable.
            </p>
            <p>
              To summarize, while the i-Hybrid function itself is enough
              to choose Green Tiger to convert your vehicle, other factors like
              Approval & Grants, other benefits that come with the conversion &
              an In-house kit design makes us a no-brainer choice.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
