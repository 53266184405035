import React from "react";
import "./NewPage.css";
import LeafInhand from "../Components/LeafInhand";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Horizontalscroll from "../Components/Horizontalscroll";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import Testimony from "../Components/Testimony";
import VideowithTxtanim from "../Components/VideowithTxtanim";
import AppFeatures from "../Components/app_features";

export default function NewPage({ open, setOpen }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const baseUrl = "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/";

  const animSeq = [
    "5 Amp Charging at Home",
    1000,
    "60 km Range on Single Charge",
    2000,
    "Save Rs. 65,000 Annually",
    2000,
    "Top Speed EV – 60 km/h",
    2000,
    "Top Speed Petrol – 80 km/h",
    2000,
    "Low Battery Indicator",
    2000,
    "Press the Button & Switch to Petrol",
    2000,
    "Bye Bye Range Anxiety",
  ];

  const appAnimSeq = [
    "Integrated App ",
    2000,
    "Fall Alert",
    2000,
    "Anti-Theft",
    2000,
    "Find My Vehicle",
    2000,
    "Ride Behaviour",
    2000,
    "Carbon Savings",
  ];

  const convertAnimSeq = [
    "Jupiter",
    2000,
    "Pleasure, Maestro",
    2000,
    "Activa, Dio",
    2000,
    "Access 125",
    2000,
  ];

  const urlGtApp = [
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%205.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%203.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%208.svg",

    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%206.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%207.svg",

    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_gt_logo/Frame%209.svg",
  ];
  const urlVechileConvert = [
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/Vechile_convert/70.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/Vechile_convert/67.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/Vechile_convert/69.svg",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/Vechile_convert/68.svg",
  ];
  const GtText = [
    "Cost & Carbon Savings Data",
    " Fall Alert",
    "Integration with ICE & EV Powertrain",
    "Live Vehicle Health Diagnosis",
    " Theft Resolution",
    " Ride Behavior",
  ];
  const landingurl = [
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/associations_logos/81.svg",
    "../../assests/ISO.png",
    "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/associations_logos/16.svg",
    "../../assests/arai.png",
    "../../assests/patent.png",
  ];
  const landingText = [
    "Supported",
    "ISO",
    "Made In India",
    "ARAI",
    "6 Patents",
  ];
  const VechileConvertText = [
    "Book Now",
    "EMI Calculator",
    "Carbon Calculator",
    "FAQ",
  ];

  return (
    <div>
      <Nav open={open} setOpen={setOpen} />
      <div
        style={{
          marginTop: "calc(90px + 3rem)",
          backgroundImage:
            "url('https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/2.svg')",
          overflow: "hidden",
        }}
      >
        <VideowithTxtanim
          title={"World’s 1st i-Hybrid"}
          videolink={`https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/videos/video_final.mp4`}
          animationSeq={animSeq}
          justifyContentBetween={true}
          width={false}
          url={landingurl}
          Text={landingText}
        />
      </div>
      <LeafInhand />
      <AppFeatures
        title={"STAY CONNECTED"}
        videolink={`${baseUrl}website_3_0/videos/App_Video.mp4`}
        animationSeq={appAnimSeq}
        justifyContentBetween={true}
        icon={true}
        url={urlGtApp}
        width={true}
        Text={GtText}
        gtalign={true}
        animation={true}
        typingwithLogo={true}
        bg={
          "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/app_hand/Home.svg"
        }
      />

      <Horizontalscroll />
      <VideowithTxtanim
        title={"VEHICLES WE CONVERT"}
        videolink={`${baseUrl}website_3_0/videos/vehicle_we_convert.mp4`}
        animationSeq={convertAnimSeq}
        icon={true}
        url={urlVechileConvert}
        width={false}
        Text={VechileConvertText}
        animation={false}
        bg={
          "https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/2.svg"
        }
        typingwithLogo={true}
        open_video={open}
        setOpen_video={setOpen}
      />

      <Testimony />
      <Footer />
    </div>
  );
}
