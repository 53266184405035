import "./App.css";
import { Routes, Route } from "react-router-dom";
import NewPage from "./Pages/NewPage";
import AboutUs from "./Pages/about-us/about-us";
import Accordion from "./Components/Accordian";
import Pending from "./Pages/Pending";
import Blog from "./Pages/blogs/Blog";
import { useState } from "react";
import BlogLists from "./Pages/blogs/BlogLists";
import BlogContent from "./Pages/blogs/BlogContent";
import PrivacyPolicy from "./Components/Privacy_policy/privacy_policy";
import Locate from "./Pages/loacte/Locate";

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}

        <Route path="/" element={<NewPage open={open} setOpen={setOpen} />} />
        <Route
          path="/about"
          element={<AboutUs open={open} setOpen={setOpen} />}
        />
        <Route path="/locate" element={<Locate />} />
        <Route path="/FAQ" element={<Accordion />} />
        <Route path="/products" element={<Pending />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blogs" element={<Blog open={open} setOpen={setOpen} />}>
          <Route path="/blogs" element={<BlogLists />} />
          <Route path="/blogs/:name" element={<BlogContent />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
