import React from "react";
import "./TemporaryDrawer.css";
import { Link } from "react-router-dom";

export default function TemporaryDrawer({ opendrawer, setopendrawer }) {
  if (!opendrawer) return null;
  return (
    <div className="drawer-container d-flex flex-column p-3 gap-3">
      <i
        className="fa-solid fa-square-xmark fs-3 gt-color align-self-end"
        onClick={() => setopendrawer(!opendrawer)}
      ></i>
      <div className="drawer-products d-flex justify-content-between">
        <img
          src="https://cdn-icons-png.flaticon.com/512/1311/1311144.png"
          alt="error"
          width={"20px"}
        />
        <Link to={"/products"} className="text-start ">
          Our Products
        </Link>
      </div>
      <div className="drawer-about-us d-flex justify-content-between">
        <img
          src="https://cdn-icons-png.flaticon.com/512/615/615075.png"
          alt="error"
          width={"20px"}
        />
        <Link to="/about" className="text-start">
          About Us
        </Link>
      </div>
      <div className="drawer-about-us d-flex justify-content-between">
        <img
          src="https://cdn-icons-png.flaticon.com/512/1330/1330233.png"
          alt="error"
          width={"20px"}
        />
        <Link to="/blogs" className="text-start">
          Blogs
        </Link>
      </div>
      <div className=" d-flex justify-content-between ">
        <img
          src="https://cdn-icons-png.flaticon.com/512/9645/9645342.png"
          alt="error"
          width={"20px"}
        />
        <Link
          href="https://goo.gl/maps/aBQ7ULttysAdo8HV7"
          className="text-start"
          to={"/locate"}
          style={{ color: "black", textDecoration: "none" }}
        >
          Locate Us
        </Link>
      </div>
    </div>
  );
}
