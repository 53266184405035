import React from "react";
import "./Styles/Testimonials.css";

export default function TestimonialsCard({ url, name, text }) {
  return (
    <div className="test-container d-flex justify-content-between flex-column flex-sm-row align-items-center">
      <div className="d-flex flex-column align-items-center gap-3">
        <div
          className="test-image"
          style={{ backgroundImage: `url(${url})` }}
        ></div>
        <span className="gt-color">{name}</span>
      </div>
      <div className="test-text-container">
        <p className="text-center text-sm-start text-black testi-text">
          {text}
        </p>
      </div>
    </div>
  );
}
